import { Subset } from '../generics';
import { Address } from './address';
import { ContactBankInformation } from './contactBankInformation';
import { ContractLine } from './contractLine';
import { Country } from './country';
import { DraftLine } from './draftLine';
import { Booking } from './freightBooking';
import { PlaceMaxWeights } from './placeMaxWeights';
import { PropertyDocument } from './propertyDocument';
import { ServiceOrder } from './serviceOrder';
import { WeightTicket } from './weightTicket';

export enum CommonCities {
  TO_BE_DETERMINED = 123459,
}

export type City = {
  id: number;
  countryKey: number;
  name: string;
  locode: string | null;
  placeType: number | null;
  coordLat: string | null;
  coordLong: string | null;
  comments: string | null;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  versionNb: number;
  country?: Country;
  address?: Address[];
  elementsAsDestination?: ContractLine[];
  physicalDraftLinesAsDestination?: DraftLine[];
  contractLinesAsIncotermPlace?: ContractLine[];
  draftLinesAsIncotermPlace?: DraftLine[];
  weightTickets?: WeightTicket[];
  serviceOrderAsOrigin?: ServiceOrder[];
  serviceOrderAsDestination?: ServiceOrder[];
  bookingsAsPlaceOfReceipt?: Booking[];
  bookingsAsLoadingPort?: Booking[];
  bookingsAsUnloadingPort?: Booking[];
  bookingsAsFinalDestination?: Booking[];
  bookingAsTranshipmentPort?: Booking[];
  shipmentsAsDetination?: PropertyDocument[];
  shipmentsAsOrigin?: PropertyDocument[];
  //translations?: PlaceText[]
  bankInformationAsBeneficiaryCities?: ContactBankInformation[];
  placeMaxWeights?: PlaceMaxWeights[];
};
export type CreateCityRequest = Subset<City, 'name', 'countryKey' | 'locode'>;
export type UpdateCityRequest = Pick<City, 'id'> & Partial<CreateCityRequest>;
export type UpsertCityMaxWeightsRequest = Pick<PlaceMaxWeights, 'placeId'> & {
  maxWeights: { methodId: number; weight: number | null }[];
};
