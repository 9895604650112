<div [formGroup]="form">
    <div class="row">
        <vertical-field class="col-xl-2 col-lg-4 col-md-8 col-12" [name]="'Number of Added Loads'">
            <numerictextbox-wrapper [formControlName]="'numberOfLoadsAdded'" [decimals]="0" [format]="'n0'"> </numerictextbox-wrapper>
        </vertical-field>
    </div>
    <div class="form-divider-line"></div>
    <div class="row">
        <vertical-field [name]="'Quantity'" class="col-xl-1 col-lg-2 col-md-4 col-sm-6">
            <numerictextbox-wrapper
            class="vertical-field-1 vertical-input-dropdown-with-btn"
            style="flex-grow: 1"
            formControlName="quantity"
            [format]="{ style: 'decimal', maximumFractionDigits: 12 }"
            #quantityField
            >
            </numerictextbox-wrapper>
            <div style="padding: 2px; font-size: small; line-height: 29px">
            {{ quantityUnit?.code }}
            </div>
        </vertical-field>
        <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6" name="Item">
            <label *ngIf="!product" style="opacity: 0.65" class="col-form-label col-form-label-sm ps-2">Select a Product</label>
            <dropdown-wrapper class="vertical-input-dropdown-with-btn" *ngIf="!!product" cyData="item" [dropdownConfig]="itemSource" [formControlName]="'item'" style="flex-grow: 1"> </dropdown-wrapper>
            <button kendoButton type="button" [icon]="'plus'" (click)="openCreateItem('internal')" *ngIf="createItemAuthorized && !!product"></button>
        </vertical-field>
        <vertical-field class="col-lg-3 col-md-4 col-sm-6" name="Custom Item Name">
            <label *ngIf="!product" style="opacity: 0.65" class="col-form-label col-form-label-sm ps-2">Select a Product</label>
            <dropdown-wrapper class="vertical-input-dropdown-with-btn" *ngIf="!!product" cyData="item" [dropdownConfig]="allItemSource" [formControlName]="'clientItem'" style="flex-grow: 1">
            </dropdown-wrapper>
            <button kendoButton type="button" [icon]="'plus'" (click)="openCreateItem('client')" *ngIf="createItemAuthorized && !!product"></button>
        </vertical-field>
        <vertical-field [name]="'Metal Units Percentage'" class="col-xl-1 col-lg-2 col-md-4 col-sm-6" *ngIf="contractClass === 'Hedged' || contractClass === 'QPHedge'">
            <numerictextbox-wrapper
            class="vertical-field-1 w-50 show-percentage"
            formControlName="metalPercentage"
            [decimals]="1"
            [min]="0"
            [max]="100"
            [format]="{ style: 'decimal', minimumFractionDigits: 1 }"
            >
            </numerictextbox-wrapper>
        </vertical-field>
        <vertical-field class="col-xl-3 col-lg-4 col-md-6" [name]="'Container Type'">
            <multiselect-wrapper [formControl]="form.controls.containerTypes" [dropdownConfig]="containerTypeSource"> </multiselect-wrapper>
        </vertical-field>
        <vertical-field name="Metal Control Group" class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
            <dropdown-wrapper [formControlName]="'metalControlGroup'" [dropdownConfig]="metalControlDropdown"></dropdown-wrapper>
        </vertical-field>
    </div>
    <div class="form-divider-line"></div>
    <div class="row">
        <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6" name="Material Origin">
            <dropdown-wrapper [dropdownConfig]="countrySource" formControlName="originCountry"> </dropdown-wrapper>
        </vertical-field>
        <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6" name="Incoterm Place">
            <div *ngIf="incoterm" style="padding: 2px; margin-left: 7px; font-size: small; line-height: 29px">
            {{ incoterm?.name }}
            </div>
            <dropdown-graphql class="vertical-input-dropdown-with-btn" cyData="incotermPlace" [dropdownConfig]="citySource" [formControlName]="'incotermPlace'"> </dropdown-graphql>
        </vertical-field>
        <vertical-field class="col-xl-2 col-lg-3 col-md-4 col-sm-6" name="Destination">
            <dropdown-graphql [dropdownConfig]="citySource" [formControlName]="'destination'"> </dropdown-graphql>
        </vertical-field>
        <div class="col-xl-4 col-lg-6 col-12">
            <div class="row">
            <vertical-field class="col-sm-4 col-12" name="Shipment/Delivery">
                <simple-dropdown formControlName="shipmentPeriodType" [data]="shipmentPeriodData" [valuePrimitive]="true" valueField="value" textField="label"> </simple-dropdown>
            </vertical-field>
            <vertical-field class="col-sm-4 col-12" name="Start">
                <datepicker-wrapper formControlName="shipmentPeriodStart" (valueChange)="selectNewDate($event, 'start', form.get('shipmentPeriod.shipmentPeriodStart'))"> </datepicker-wrapper>
            </vertical-field>
            <vertical-field class="col-sm-4 col-12" name="End">
                <datepicker-wrapper formControlName="shipmentPeriodEnd"></datepicker-wrapper>
            </vertical-field>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="product?.recoveryPercentageEnabled === 'Y'">
        <vertical-field name="Uses Recovery Percentage" class="col-lg-2 col-md-4 col-sm-6">
            <kendo-dropdownlist
            [readonly]="readonly"
            [data]="usesRecoveryPercentageOptions"
            [formControlName]="'usesRecoveryPercentage'"
            [valuePrimitive]="true"
            [valueField]="'value'"
            [textField]="'label'"
            >
            </kendo-dropdownlist>
        </vertical-field>
        <ng-container *ngIf="form.value?.usesRecoveryPercentage === 'Y'">
            <vertical-field [name]="'Recovery Percentage'" class="col-xl-1 col-lg-2 col-md-4 col-sm-6">
            <numerictextbox-wrapper
                class="vertical-field-1 w-50 show-percentage"
                formControlName="recoveryPercentage"
                [decimals]="1"
                [min]="0"
                [max]="100"
                [format]="{ style: 'decimal', minimumFractionDigits: 1 }"
            >
            </numerictextbox-wrapper>
            </vertical-field>
        </ng-container>
        </div>
        <div class="form-divider-line"></div>
        <div class="row">
        <simple-formula
            style="width: 100%"
            class="col-12"
            [entityName]="'Contract'"
            [formControlName]="'pricing'"
            [contractClass]="contractClass"
            [priceUnit]="priceUnit"
            [currency]="currency"
            [premiumUnit]="premiumUnit"
            [contractDate]="contractDate"
            [readonly]="false"
            [existEntity]="true"
        >
        </simple-formula>
    </div>
</div>
