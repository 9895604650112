import { YN } from '.';

export class ShipmentInformationForClientInvoice {
  shipmentId: number;
  documentName: string;
  netWeight: number;
  bookingNumber: number;
  contractNumber: number;
  contractDate: number;
  productClass: string;
  customerId: number;
  collateral: number;
  customerName: string;
  contractPaymentTermId: number;
  contractPaymentTermName: string;
  walkPrice: number;
  contractProductId: number;
  formulaText: string;
  unitId: number;
  contractPriceUnitId: number;
  contractCurrencyId: number;
  marketValuationId: number | null;
  marketSpread: number | null;
  marketPercentage: number | null;
  premiumMarketValuationId: number | null;
  premiumMarketSpread: number | null;
  premiumMarketPercentage: number | null;
  premiumUnitId: number | null;
  premiumCurrencyId: number | null;
  premium: number | null;
  fixedExchangeRate: number | null;
  fixedMarketPrice: number | null;
  fixedPremiumPrice: number | null;
  contractPriceType: 'FIXED' | 'FORMULA';
  finalDestinationEtaDate: number;
  customerProvisionalWeight: YN;
  isFixationAvailable: YN;
  isShipmentFixed: YN;
  upperPriceToleranceInMts: number;
  lowerPriceToleranceInMts: number;
  blDate: number | null;
  reservedInvoiceNumber: number | null;
  requestedInvoiceDate: number | null;
  permanentDate: number;
  suggestedInvoiceDateCase: number;
  suggestedInvoiceDate: number | null;
  decimalPrecision: number;
}

export enum PrecisionDecimals {
  TWO = 2,
  FOUR = 4,
}

export const NumberOfDecimals = [
  { value: PrecisionDecimals.TWO, label: '2' },
  { value: PrecisionDecimals.FOUR, label: '4' },
];
