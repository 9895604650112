import { PartialBooking, PartialInvoice, ShipmentFinderData } from 'src/app/+modules/+quality-control/containers/discrepancy/discrepancy.component';
import { EnumLabels, Subset } from '../generics';
import { BudgetElement } from './budgetElement';
import { Comment } from './comment';
import { Contact } from './contact';
import { YN } from './enums';
import { CreateEvidenceRequest, Evidence } from './evidence';
import { PaymentTerm } from './paymentTerm';
import { PropertyDocument } from './propertyDocument';
import { ServiceOrder } from './serviceOrder';
import { ShipmentFinderResult } from './shipment';
import { WeightTicket } from './weightTicket';
import { DiscrepancyListViewRow } from 'src/app/+modules/+quality-control/containers/discrepancy-list/discrepancy-list.component';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { ListResponse } from '../ListResponse';
import { endpoints } from '../apiEndpoints';
import { JournalHeader } from './journal';

export enum DiscrepancyType {
  WEIGHT = 1,
  QUALITY = 2,
  LOGISTICS = 3,
  PERCENT_RECOVERY = 4,
  CONTAMINATION = 5,
  DEMURRAGE_AND_DETENTION = 6,
}

export const DiscrepancyTypeOptions = [
  { value: DiscrepancyType.DEMURRAGE_AND_DETENTION, label: 'Demurrage and Detention' },
  { value: DiscrepancyType.LOGISTICS, label: 'Logistics' },
  { value: DiscrepancyType.CONTAMINATION, label: 'Contamination' },
  { value: DiscrepancyType.PERCENT_RECOVERY, label: 'Percent Recovery' },
  { value: DiscrepancyType.QUALITY, label: 'Quality' },
  { value: DiscrepancyType.WEIGHT, label: 'Weight' },
];

export enum DiscrepancyStatus {
  NEW_DISCREPANCY = 1,
  NOTIFIED_SUPPLIER = 2,
  EVIDENCE_REQUESTED = 3,
  SAMPLE_IN_TRUST = 4,
  THIRD_PARTY_INSPECTION = 5,
  REWORK_IN_PROCESS = 6,
  CLEANING_PROCESS = 7,
  WAITING_TRADER = 8,
  SENT_SUPPLIER = 9,
  NEGOTIATION = 10,
  FIRST_REMINDER = 11,
  SECOND_REMINDER = 12,
  LAST_REMINDER = 13,
  SETTLEMENT_PROPOSED = 14,
  WAITING_FIXATION = 15,
  SETTLED = 16,
  PAID = 17,
  NOT_ACCEPTED = 18,
  WAIVED = 19,
  DOMESTIC = 20,
  WEIGHT_REPORT = 21,
  IN_REVIEW = 22,
}

export const draftStatuses = [DiscrepancyStatus.NEW_DISCREPANCY];

export const inProgressStatuses = [
  DiscrepancyStatus.DOMESTIC,
  DiscrepancyStatus.NOTIFIED_SUPPLIER,
  DiscrepancyStatus.EVIDENCE_REQUESTED,
  DiscrepancyStatus.SAMPLE_IN_TRUST,
  DiscrepancyStatus.THIRD_PARTY_INSPECTION,
  DiscrepancyStatus.REWORK_IN_PROCESS,
  DiscrepancyStatus.CLEANING_PROCESS,
  DiscrepancyStatus.WAITING_TRADER,
  DiscrepancyStatus.SENT_SUPPLIER,
  DiscrepancyStatus.NEGOTIATION,
  DiscrepancyStatus.FIRST_REMINDER,
  DiscrepancyStatus.SECOND_REMINDER,
  DiscrepancyStatus.LAST_REMINDER,
  DiscrepancyStatus.SETTLEMENT_PROPOSED,
  DiscrepancyStatus.WAITING_FIXATION,
  DiscrepancyStatus.IN_REVIEW,
];

export const invoicedStatuses = [DiscrepancyStatus.PAID, DiscrepancyStatus.SETTLED, DiscrepancyStatus.NOT_ACCEPTED, DiscrepancyStatus.WAIVED, DiscrepancyStatus.WEIGHT_REPORT];

export const inProgressOrLaterStatuses = inProgressStatuses.concat(invoicedStatuses);
export const inProgressOrEarlierStatuses = inProgressStatuses.concat(draftStatuses);
export const draftOrLaterStatuses = draftStatuses.concat(inProgressOrLaterStatuses);

export enum DiscrepancyCategory {
  CLAIMS = 12888135,
  INCLUDED_IN_CONTRACT_PRICE = 23024675,
  REJECTION = 23024919,
  ROYCE_ERROR_FIN = 23033784,
  CUSTOMER_DEFAULT = 23033793,
  ROYCE_ERROR_LOG = 23034433,
  ROYCE_ERROR_MC = 23034434,
  ROYCE_ERROR_TRD = 23034435,
  SUPPLIER_RESPONSIBLE = 23034814,
  CUSTOMER_RESPONSIBLE = 23045875,
}

export const DiscrepancyCategoryOptions = [
  { value: DiscrepancyCategory.CLAIMS, label: 'Claims' },
  { value: DiscrepancyCategory.INCLUDED_IN_CONTRACT_PRICE, label: 'Included In Contract Price' },
  { value: DiscrepancyCategory.REJECTION, label: 'Rejection' },
  { value: DiscrepancyCategory.CUSTOMER_DEFAULT, label: 'Customer Default' },
  { value: DiscrepancyCategory.SUPPLIER_RESPONSIBLE, label: 'Supplier Responsible' },
  { value: DiscrepancyCategory.CUSTOMER_RESPONSIBLE, label: 'Customer Responsible' },
  { value: DiscrepancyCategory.ROYCE_ERROR_FIN, label: 'Royce Error - FIN' },
  { value: DiscrepancyCategory.ROYCE_ERROR_LOG, label: 'Royce Error - LOG' },
  { value: DiscrepancyCategory.ROYCE_ERROR_MC, label: 'Royce Error - MC' },
  { value: DiscrepancyCategory.ROYCE_ERROR_TRD, label: 'Royce Error - TRD' },
];

export const discrepancyStatusLabels = {
  [DiscrepancyStatus.NEW_DISCREPANCY]: 'New Discrepancy',
  [DiscrepancyStatus.NOTIFIED_SUPPLIER]: 'Notified Supplier',
  [DiscrepancyStatus.EVIDENCE_REQUESTED]: 'Evidence Requested',
  [DiscrepancyStatus.SAMPLE_IN_TRUST]: 'Sample in Trust',
  [DiscrepancyStatus.THIRD_PARTY_INSPECTION]: 'Third Party Inspection',
  [DiscrepancyStatus.REWORK_IN_PROCESS]: 'Rework in Process',
  [DiscrepancyStatus.CLEANING_PROCESS]: 'Cleaning Process',
  [DiscrepancyStatus.WAITING_TRADER]: 'Waiting Trader',
  [DiscrepancyStatus.SENT_SUPPLIER]: 'Sent Supplier',
  [DiscrepancyStatus.NEGOTIATION]: 'Negotiation',
  [DiscrepancyStatus.FIRST_REMINDER]: 'First Reminder',
  [DiscrepancyStatus.SECOND_REMINDER]: 'Second Reminder',
  [DiscrepancyStatus.LAST_REMINDER]: 'Last Reminder',
  [DiscrepancyStatus.SETTLEMENT_PROPOSED]: 'Settlement Proposed',
  [DiscrepancyStatus.WAITING_FIXATION]: 'Waiting Fixation',
  [DiscrepancyStatus.PAID]: 'Paid',
  [DiscrepancyStatus.SETTLED]: 'Settled',
  [DiscrepancyStatus.NOT_ACCEPTED]: 'Not Accepted',
  [DiscrepancyStatus.WAIVED]: 'Waived',
  [DiscrepancyStatus.WEIGHT_REPORT]: 'Weight Report',
  [DiscrepancyStatus.DOMESTIC]: 'New Domestic',
  [DiscrepancyStatus.IN_REVIEW]: 'In Review',
};

export const discrepancyStatuses = Array.from(draftStatuses.values())
  .map((s) => {
    return { value: s, label: discrepancyStatusLabels[s], phase: 'Draft' };
  })
  .concat(
    Array.from(inProgressStatuses.values()).map((s) => {
      return { value: s, label: discrepancyStatusLabels[s], phase: 'In Progress' };
    })
  )
  .concat(
    Array.from(invoicedStatuses.values()).map((s) => {
      return { value: s, label: discrepancyStatusLabels[s], phase: 'Invoiced' };
    })
  );

export enum VoucherType {
  INVOICE = 1,
  CREDIT = 2,
  DEBIT = 3,
  WRITE_OFF = 4,
}

export const VoucherTypes: EnumLabels<VoucherType> = [
  { value: VoucherType.CREDIT, label: 'Credit' },
  { value: VoucherType.DEBIT, label: 'Debit' },
  { value: VoucherType.INVOICE, label: 'Invoice' },
];

export enum SupplierSideCounterpartyTypes {
  SUPPLIER = 1,
  THIRD_PARTY = 3,
  NONE = 4,
}
export enum CustomerSideCounterpartyTypes {
  CUSTOMER = 2,
  THIRD_PARTY = 3,
  NONE = 4,
}
export enum InstallmentTypes {
  PER_INVOICE = 1,
  PER_SHIPMENT = 2,
  OTHER = 3,
}

export const InstallmentTypeOptions = [
  { value: InstallmentTypes.PER_INVOICE, label: 'Per Invoice' },
  { value: InstallmentTypes.PER_SHIPMENT, label: 'Per Shipment' },
  { value: InstallmentTypes.OTHER, label: 'Other' },
];

export const CustomerSideCounterpartyTypeOptions = [
  { value: CustomerSideCounterpartyTypes.CUSTOMER, label: 'Customer' },
  { value: CustomerSideCounterpartyTypes.THIRD_PARTY, label: 'Third Party' },
  { value: CustomerSideCounterpartyTypes.NONE, label: 'None' },
];

export const SupplierSideCounterpartyTypeOptions = [
  { value: SupplierSideCounterpartyTypes.SUPPLIER, label: 'Supplier' },
  { value: SupplierSideCounterpartyTypes.THIRD_PARTY, label: 'Third Party' },
  { value: SupplierSideCounterpartyTypes.NONE, label: 'None' },
];

export type DiscrepancyWeightTicket = {
  discrepancyId: number;
  customerWeightTicketId: number;
  supplierWeightTicketId: number;
  chunkKey: number;
  shipment?: PropertyDocument;
  weightTicketAsCustomer?: WeightTicket;
  weightTicketAsSupplier?: WeightTicket;
};

export type CreateDiscrepancyWeightTicketRequest = {
  customerWeightTicketId: number | null;
  supplierWeightTicketId: number | null;
  chunkKey: number;
};

export type UpdateDiscrepancyWeightTicketRequest = {
  customerWeightTicketId?: number | null;
  supplierWeightTicketId?: number | null;
  chunkKey: number;
};

export type Discrepancy = WeightDiscrepancy | LogisticsDiscrepancy | QualityDiscrepancy | PercentRecoveryDiscrepancy | ContaminationDiscrepancy | DemurrageAndDetentionDiscrepancy;
export type CreateDiscrepancyRequest =
  | CreateWeightDiscrepancyRequest
  | CreateLogisticsDiscrepancyRequest
  | CreateQualityDiscrepancyRequest
  | CreatePercentRecoveryDiscrepancyRequest
  | CreateContaminationDiscrepancyRequest
  | CreateDemurrageAndDetentionDiscrepancyRequest;
export type UpdateDiscrepancyRequest =
  | UpdateWeightDiscrepancyRequest
  | UpdateLogisticsDiscrepancyRequest
  | UpdateQualityDiscrepancyRequest
  | UpdatePercentRecoveryDiscrepancyRequest
  | UpdateContaminationDiscrepancyRequest
  | UpdateDemurrageAndDetentionDiscrepancyRequest;

export type DiscrepancySides = {
  supplierCounterpartyId: number;
  supplierStatus: DiscrepancyStatus;
  supplierBudgetElementId: number;
  supplierVoucherType: VoucherType;
  supplierOriginalAmount: number;
  supplierSettledAmount: number;
  supplierPaymentInstructions: string;
  supplierExternalReference: string;
  supplierDescription: string;

  customerCounterpartyId: number;
  customerStatus: DiscrepancyStatus;
  customerBudgetElementId: number;
  customerVoucherType: VoucherType;
  customerOriginalAmount: number;
  customerSettledAmount: number;
  customerPaymentInstructions: string;
  customerExternalReference: string;
  customerDescription: string;

  categoryId: number | null;
  supplierCounterpartyType: SupplierSideCounterpartyTypes;
  customerCounterpartyType: CustomerSideCounterpartyTypes;
  supplierInstallmentType: InstallmentTypes | null;
  supplierInstallmentCount: number | null;
  supplierCurrencyId: number | null;
  customerCurrencyId: number | null;
  supplierByPacking: YN | null;
  customerByPacking: YN | null;
  supplierPaymentTermId: number | null;
  customerPaymentTermId: number | null;
};

export type BaseDiscrepancy = DiscrepancySides & {
  id: number;
  type: DiscrepancyType;
  categoryId: number;
  createdBy: number;
  updatedBy: number | null;
  creationDate: Date;
  updateDate: Date;
  date: Date;
  archived: YN;
  purchaseProvisionJournalId: number | null;
  saleProvisionJournalId: number | null;

  supplierBudgetElement?: BudgetElement;
  supplierCounterparty?: Contact;
  supplierPaymentTerm?: PaymentTerm;
  supplierAsServiceOrder?: ServiceOrder;
  customerBudgetElement?: BudgetElement;
  customerCounterparty?: Contact;
  customerPaymentTerm?: PaymentTerm;
  customerAsServiceOrder?: ServiceOrder;

  createdByUser?: Contact;
  updatedByUser?: Contact;

  evidence?: Evidence[];
  comments?: Comment[];
  shipments?: PropertyDocument[];
  relatedDiscrepancies: BaseDiscrepancy[];
} & {
  shipmentFinderResults: ShipmentFinderResult[];
  shipmentFinderData: ShipmentFinderData;
  linkedDiscrepanciesData: LinkedDiscrepanciesData[];
  purchaseProvisionJournal?: JournalHeader;
  saleProvisionJournal?: JournalHeader;
};

export type BaseCreateDiscrepancyRequest = DiscrepancySides & {
  categoryId: number;
  type: DiscrepancyType;
  evidence?: CreateEvidenceRequest[];

  shipmentIds: number[];
  date: Date;
};

export type BaseUpdateDiscrepancyRequest = Partial<DiscrepancySides> & {
  id: number;
  shipmentIds: number[];
  date: Date;
};

export type WeightDiscrepancy = BaseDiscrepancy & {
  type: DiscrepancyType.WEIGHT;
  discrepancyWeightTickets: DiscrepancyWeightTicket[];
};

export type CreateWeightDiscrepancyRequest = BaseCreateDiscrepancyRequest & {
  type: DiscrepancyType.WEIGHT;
  discrepancyWeightTickets: CreateDiscrepancyWeightTicketRequest[];
};

export type UpdateWeightDiscrepancyRequest = BaseUpdateDiscrepancyRequest & {
  type?: DiscrepancyType.WEIGHT;
  discrepancyWeightTickets?: (CreateDiscrepancyWeightTicketRequest | UpdateDiscrepancyWeightTicketRequest)[];
};

export type LogisticsDiscrepancy = BaseDiscrepancy & {
  type: DiscrepancyType.LOGISTICS;
};

export type CreateLogisticsDiscrepancyRequest = BaseCreateDiscrepancyRequest & {
  type: DiscrepancyType.LOGISTICS;
};

export type UpdateLogisticsDiscrepancyRequest = BaseUpdateDiscrepancyRequest & {
  type?: DiscrepancyType.LOGISTICS;
};

export type QualityDiscrepancy = BaseDiscrepancy & {
  type: DiscrepancyType.QUALITY;
};

export type CreateQualityDiscrepancyRequest = BaseCreateDiscrepancyRequest & {
  type: DiscrepancyType.QUALITY;
};

export type UpdateQualityDiscrepancyRequest = BaseUpdateDiscrepancyRequest & {
  type: DiscrepancyType.QUALITY;
};

type DemurrageAndDetentionBase = {
  type: DiscrepancyType.DEMURRAGE_AND_DETENTION;
  agreedDays: number;
  bookingDays: number;
  actualDays: number;
  overageDays: number;
};

export type DemurrageAndDetentionDiscrepancy = BaseDiscrepancy & DemurrageAndDetentionBase;

export type CreateDemurrageAndDetentionDiscrepancyRequest = BaseCreateDiscrepancyRequest & DemurrageAndDetentionBase;

export type UpdateDemurrageAndDetentionDiscrepancyRequest = BaseUpdateDiscrepancyRequest & Partial<DemurrageAndDetentionBase>;

type PercentRecoveryBase = {
  type: DiscrepancyType.PERCENT_RECOVERY;
  supplierContractPercentageRecovery: number;
  customerContractPercentageRecovery: number;
  actualPercentageRecovery: number;
};

export type PercentRecoveryDiscrepancy = BaseDiscrepancy & PercentRecoveryBase;

export type CreatePercentRecoveryDiscrepancyRequest = BaseCreateDiscrepancyRequest & PercentRecoveryBase;

export type UpdatePercentRecoveryDiscrepancyRequest = BaseUpdateDiscrepancyRequest & Partial<PercentRecoveryBase>;

type ContaminationBase = {
  type: DiscrepancyType.CONTAMINATION;
  actualPercentageContamination: number;
  supplierContractPercentageContamination: number;
  customerContractPercentageContamination: number;
};

export type ContaminationDiscrepancy = BaseDiscrepancy & ContaminationBase;

export type CreateContaminationDiscrepancyRequest = BaseCreateDiscrepancyRequest & ContaminationBase;

export type UpdateContaminationDiscrepancyRequest = BaseUpdateDiscrepancyRequest & Partial<ContaminationBase>;

export type LinkUnlinkDiscrepancyRequest = Subset<BaseDiscrepancy, 'id'> & {
  relatedDiscrepanciesIds: number[];
};

export type LinkedDiscrepanciesData = Omit<ShipmentFinderData, 'saleInvoices' | 'purchaseInvoices' | 'purchaseTraderContact' | 'saleTraderContact' | 'shipmentFinderResults' | 'freightBooking'> &
  Subset<Discrepancy, 'id' | 'type'> & {
    freightBookingId?: number;
    freightBookingNumber?: number;
  };

/**
 * Returns true if status of given side can be set to the In Progress stage (stage 2)
 */
export function discrepancyValidForInProgress(discrepancy: Discrepancy, side: 'customerStatus' | 'supplierStatus') {
  let requirements: any[] = [];
  if (side === 'customerStatus') {
    requirements.push(discrepancy.customerOriginalAmount);
    requirements.push(discrepancy.customerVoucherType);
    requirements.push(discrepancy.categoryId);
    switch (discrepancy.type) {
      case DiscrepancyType.CONTAMINATION:
        requirements.push(discrepancy.customerContractPercentageContamination);
        requirements.push(discrepancy.actualPercentageContamination);
        break;
      case DiscrepancyType.DEMURRAGE_AND_DETENTION:
        requirements.push(discrepancy.overageDays);
        requirements.push(discrepancy.actualDays);
        requirements.push(discrepancy.agreedDays);
        requirements.push(discrepancy.overageDays);
        break;
      case DiscrepancyType.PERCENT_RECOVERY:
        requirements.push(discrepancy.customerContractPercentageRecovery);
        requirements.push(discrepancy.actualPercentageRecovery);
        break;
      case DiscrepancyType.WEIGHT:
        for (let t of discrepancy.discrepancyWeightTickets) {
          requirements.push(t.customerWeightTicketId);
        }
      default:
        break;
    }
  }
  if (side === 'supplierStatus') {
    requirements.push(discrepancy.supplierOriginalAmount);
    requirements.push(discrepancy.supplierVoucherType);
    requirements.push(discrepancy.categoryId);
    switch (discrepancy.type) {
      case DiscrepancyType.CONTAMINATION:
        requirements.push(discrepancy.supplierContractPercentageContamination);
        break;
      case DiscrepancyType.DEMURRAGE_AND_DETENTION:
        requirements.push(discrepancy.overageDays);
        requirements.push(discrepancy.actualDays);
        requirements.push(discrepancy.agreedDays);
        requirements.push(discrepancy.overageDays);
        break;
      case DiscrepancyType.PERCENT_RECOVERY:
        requirements.push(discrepancy.supplierContractPercentageRecovery);
        break;
      case DiscrepancyType.WEIGHT:
        for (let t of discrepancy.discrepancyWeightTickets) {
          requirements.push(t.supplierWeightTicketId);
        }
      default:
        break;
    }
  }
  return requirements.every((val) => val !== null);
}

/**
 * Returns true if status of given side can be set to the Settled stage (stage 3)
 */
export function discrepancyValidForSettled(discrepancy: Discrepancy, side: 'customerStatus' | 'supplierStatus') {
  if (side === 'customerStatus') {
    return discrepancyValidForInProgress(discrepancy, side) && discrepancy.customerSettledAmount !== null;
  }
  if (side === 'supplierStatus') {
    return (
      discrepancyValidForInProgress(discrepancy, side) && discrepancy.supplierInstallmentCount !== null && discrepancy.supplierInstallmentType !== null && discrepancy.supplierSettledAmount !== null
    );
  }
  return false;
}

export async function getDataFromShipments(delegate: DelegateService, shipments?: ShipmentFinderResult[]): Promise<ShipmentFinderData> {
  if (!(shipments && shipments.length > 0)) return;

  const api = delegate.getService('api');

  // Obtain the genealogies of the selected shipments and use it to populate undefined purchase/sale values
  const getGenealogies = await api.run<ListResponse<ShipmentFinderResult>>(endpoints.shipmentLookupGenealogy, { filters: { shipmentId: shipments.map((item) => item.shipmentId) } });
  const shipmentsGenealogies = getGenealogies.list || [];
  if (!(shipmentsGenealogies.length > 0)) return;

  const shipmentsData: ShipmentFinderResult[] = [];
  const purchaseInvoices: PartialInvoice[] = [];
  const saleInvoices: PartialInvoice[] = [];
  const freightBooking: PartialBooking[] = [];

  for (const s of shipments) {
    // Find the genealogy of the current shipment
    const currentGenealogy = shipmentsGenealogies.find((item) => item.shipmentId === s.shipmentId);
    if (!currentGenealogy) continue;

    const {
      purchaseContractId,
      purchaseContractNumber,
      purchaseCounterpartyId,
      purchaseCounterpartyName,
      purchaseInvoiceId,
      purchaseInvoiceNumber,
      purchaseTraderId,
      purchaseTraderName,
      saleContractId,
      saleContractNumber,
      saleCounterpartyId,
      saleCounterpartyName,
      saleInvoiceId,
      saleInvoiceNumber,
      saleTraderId,
      saleTraderName,
    } = currentGenealogy;

    // Use a helper function to handle sale/purchase undefined values
    const setShipmentProperty = <K extends keyof ShipmentFinderResult>(property: K, value: ShipmentFinderResult[K]) => {
      if (!s[property]) s[property] = value;
    };

    // Set values from currentGenealogy to shipment if they are not already set
    setShipmentProperty('purchaseContractId', purchaseContractId);
    setShipmentProperty('purchaseContractNumber', purchaseContractNumber);
    setShipmentProperty('purchaseCounterpartyId', purchaseCounterpartyId);
    setShipmentProperty('purchaseCounterpartyName', purchaseCounterpartyName);
    setShipmentProperty('purchaseInvoiceId', purchaseInvoiceId);
    setShipmentProperty('purchaseInvoiceNumber', purchaseInvoiceNumber);
    setShipmentProperty('purchaseTraderId', purchaseTraderId);
    setShipmentProperty('purchaseTraderName', purchaseTraderName);
    setShipmentProperty('saleContractId', saleContractId);
    setShipmentProperty('saleContractNumber', saleContractNumber);
    setShipmentProperty('saleCounterpartyId', saleCounterpartyId);
    setShipmentProperty('saleCounterpartyName', saleCounterpartyName);
    setShipmentProperty('saleInvoiceId', saleInvoiceId);
    setShipmentProperty('saleInvoiceNumber', saleInvoiceNumber);
    setShipmentProperty('saleTraderId', saleTraderId);
    setShipmentProperty('saleTraderName', saleTraderName);

    // Push sale invoice data if not already exist in the array
    if (s.saleInvoiceId && s.saleInvoiceNumber) if (!saleInvoices.some((si) => si.id === s.saleInvoiceId)) saleInvoices.push({ id: s.saleInvoiceId, number: s.saleInvoiceNumber });

    // Push purchase invoice data if not already exist in the array
    if (s.purchaseInvoiceId && s.purchaseInvoiceNumber)
      if (!purchaseInvoices.some((pi) => pi.id === s.purchaseInvoiceId)) purchaseInvoices.push({ id: s.purchaseInvoiceId, number: s.purchaseInvoiceNumber });

    // Push booking data if not already exist in the array
    if (s.bookingId && s.bookingNumber) if (!freightBooking.some((b) => b.id === s.bookingId)) freightBooking.push({ id: s.bookingId, number: s.bookingNumber });

    shipmentsData.push(s);
  }

  // Function to obtain Set with the unique ids
  const getUniqueIds = (shipments: ShipmentFinderResult[], prop: keyof ShipmentFinderResult): Set<number | null> => {
    return new Set(shipments.map((s) => s[prop] as number | null).filter(Boolean));
  };

  const saleIds = getUniqueIds(shipments, 'saleContractId');
  const purchaseIds = getUniqueIds(shipments, 'purchaseContractId');
  const saleCounterpartyIds = getUniqueIds(shipments, 'saleCounterpartyId');
  const purchaseCounterpartyIds = getUniqueIds(shipments, 'purchaseCounterpartyId');
  const saleTraderIds = getUniqueIds(shipments, 'saleTraderId');
  const purchaseTraderIds = getUniqueIds(shipments, 'purchaseTraderId');
  const purchaseContractIds = getUniqueIds(shipments, 'purchaseContractId');
  const saleContractIds = getUniqueIds(shipments, 'saleContractId');

  // Function to obtain string values related to sale/purchase data to be populate in form
  const getPurchasOrSaleStringValue = (ids: Set<number | null>, shipment: ShipmentFinderResult, propertyName: keyof ShipmentFinderResult): string => {
    return ids.size === 1 ? (!!shipment[propertyName] ? `${shipment[propertyName]}` : 'Conflicting') : 'None';
  };

  const saleContract = getPurchasOrSaleStringValue(saleIds, shipments[0], 'saleContractNumber');
  const purchaseContract = getPurchasOrSaleStringValue(purchaseIds, shipments[0], 'purchaseContractNumber');
  const saleCounterparty = getPurchasOrSaleStringValue(saleCounterpartyIds, shipments[0], 'saleCounterpartyName');
  const purchaseCounterparty = getPurchasOrSaleStringValue(purchaseCounterpartyIds, shipments[0], 'purchaseCounterpartyName');
  const saleTrader = getPurchasOrSaleStringValue(saleTraderIds, shipments[0], 'saleTraderName');
  const purchaseTrader = getPurchasOrSaleStringValue(purchaseTraderIds, shipments[0], 'purchaseTraderName');

  // Function to obtain Contact type values related to sale/purchase data to be populate in form
  const getPurchasOrSaleContactValue = (
    ids: Set<number | null>,
    shipment: ShipmentFinderResult,
    propertyName: keyof ShipmentFinderResult,
    propertyId: keyof ShipmentFinderResult
  ): Pick<Contact, 'displayName' | 'id'> | null => {
    return ids.size === 1 && !!shipment[propertyName] && !!shipment[propertyId] ? { id: shipment[propertyId] as number, displayName: shipment[`${propertyName}`] as string } : null;
  };

  const saleContact = getPurchasOrSaleContactValue(saleCounterpartyIds, shipments[0], 'saleCounterpartyName', 'saleCounterpartyId');
  const purchaseContact = getPurchasOrSaleContactValue(purchaseCounterpartyIds, shipments[0], 'purchaseCounterpartyName', 'purchaseCounterpartyId');
  const saleTraderContact = getPurchasOrSaleContactValue(saleTraderIds, shipments[0], 'saleTraderName', 'saleTraderId');
  const purchaseTraderContact = getPurchasOrSaleContactValue(purchaseTraderIds, shipments[0], 'purchaseTraderName', 'purchaseTraderId');

  const purchaseContractId: number | null = purchaseContractIds.size === 1 ? purchaseContractIds.values().next().value : null;
  const saleContractId: number | null = saleContractIds.size === 1 ? saleContractIds.values().next().value : null;

  return {
    saleContract,
    purchaseContract,
    saleCounterparty,
    purchaseCounterparty,
    saleTrader,
    purchaseTrader,
    purchaseInvoices,
    saleInvoices,
    freightBooking,
    saleContact,
    purchaseContact,
    saleTraderContact,
    purchaseTraderContact,
    saleContractId,
    purchaseContractId,
    shipmentFinderResults: shipmentsData,
  };
}

export function mapRelatedDiscrepanciesData(listDiscrepancies: DiscrepancyListView[]): LinkedDiscrepanciesData[] {
  const linkedDiscrepanciesData: LinkedDiscrepanciesData[] = [];
  listDiscrepancies.forEach((ld) => {
    const bookingsIds: number[] = ld.freightBookingId ? ld.freightBookingId.split(',').map((id) => parseInt(id)) : [];
    const bookingsNumbers: number[] = ld.freightBooking ? ld.freightBooking.split(',').map((n) => parseInt(n)) : [];
    const linkedMappedData = {
      ...ld,
      id: ld.discrepancyId,
      type: ld.discrepancyType,
      saleCounterparty: ld.customerCounterparty,
      saleCounterpartyId: ld.customerCounterpartyId,
      purchaseCounterparty: ld.supplierCounterparty,
      purchaseCounterpartyId: ld.supplierCounterpartyId,
      saleContact: {
        id: ld.customerCounterpartyId,
        displayName: ld.customerCounterparty,
      },
      purchaseContact: {
        id: ld.supplierCounterpartyId,
        displayName: ld.supplierCounterparty,
      },
      purchaseContractId: parseInt(ld.purchaseContractId),
      saleContractId: parseInt(ld.saleContractId),
      status: invoicedStatuses.includes(ld.customerStatus) && invoicedStatuses.includes(ld.supplierStatus) ? 'Finalized' : 'Not Finalized',
    };

    linkedDiscrepanciesData.push(
      ...bookingsIds.map((item, i) => {
        return { ...linkedMappedData, freightBookingId: item, freightBookingNumber: bookingsNumbers[i] };
      })
    );
  });

  return linkedDiscrepanciesData;
}

export function amountStyle(side: 'customerSettledAmount' | 'supplierSettledAmount' | 'supplierOriginalAmount' | 'customerOriginalAmount') {
  return (params) => {
    const d: DiscrepancyListViewRow = params.data;
    if (!d) return {};
    const textAlign = 'right';
    let fontWeight = 'inherit';
    let color = 'inherit';

    if (!d[side]) fontWeight = 'bold';

    if (
      (side === 'customerSettledAmount' && d.customerAmountSign === -1) ||
      (side === 'supplierSettledAmount' && d.supplierAmountSign === -1) ||
      (side === 'supplierOriginalAmount' && d.supplierAmountSign === -1) ||
      (side === 'customerOriginalAmount' && d.customerAmountSign === -1)
    )
      color = 'red';

    return { textAlign, fontWeight, color };
  };
}

export type GroupedStatus = {
  phase: string;
  statuses: { label: string; value: DiscrepancyStatus }[];
};

export type DiscrepancyListView = {
  discrepancyId: number;
  discrepancyType: DiscrepancyType;
  customerCounterparty: string | null;
  customerStatus: DiscrepancyStatus | null;
  customerCurrencyId: number | null;
  customerOriginalAmount: number | null;
  customerSettledAmount: number | null;
  customerBudgetElement: string | null;
  supplierCounterparty: string | null;
  supplierStatus: DiscrepancyStatus | null;
  supplierCurrencyId: number | null;
  supplierOriginalAmount: number | null;
  supplierSettledAmount: number | null;
  supplierBudgetElement: string | null;
  discrepancyDate: number | null;
  shipments: string | null;
  purchaseContract: string | null;
  purchaseContractId: string | null;
  purchaseTrader: string | null;
  purchaseOrigin: string | null;
  purchaseInvoice: string | null;
  saleContract: string | null;
  saleContractId: string | null;
  saleTrader: string | null;
  saleDestination: string | null;
  saleInvoice: string | null;
  freightBooking: string | null;
  freightBookingId: string | null;
  customerAmountSign: 1 | -1;
  supplierAmountSign: 1 | -1;
  customerCounterpartyType: CustomerSideCounterpartyTypes;
  customerVoucherType: VoucherType;
  supplierCounterpartyType: SupplierSideCounterpartyTypes;
  supplierVoucherType: VoucherType;
  supplierCounterpartyId: number;
  customerCounterpartyId: number;
  archived: YN;
};
