import { EnumLabels } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { Approval, CreateApprovalRequest, UpdateApprovalRequest } from './approval';
import { ArticleText } from './articleText';
import { Clause } from './clause';
import { Comment } from './comment';
import { Contact } from './contact';
import { PhysicalContractExpense } from './contractExpense';
import { ContractLine, CreateContractLineRequest, UpdateContractLineRequest } from './contractLine';
import { Currency } from './currency';
import { YN } from './enums';
import { Incoterm } from './incoterm';
import { PaymentTerm } from './paymentTerm';
import { PcPriceFixing } from './priceFixation';
import { Product } from './product';
import { PropertyDocument } from './propertyDocument';
import { Unit, UnitInfo } from './unit';

export enum Languages {
  ENGLISH = 3,
}

export enum ContractType {
  PURCHASE = 1,
  SALE = 2,
}
export const ContractTypes = enumOptionsFromEnum(ContractType);

export enum Associations {
  LME = 100098,
  BIR = 100099,
  NA = 113607,
  ISRI = 118295,
}

export enum AdvanceTypes {
  PERCENTAGE = 'P',
  AMOUNT = 'A',
  NO_ADVANCE = 'N',
}

export enum ContractClass {
  H = 'Hedged',
  NH = 'NoHedge',
  QP = 'QPHedge',
  A = 'Admin',
}

export const ContractClasses: EnumLabels<ContractClass> = [
  { value: ContractClass.A, label: 'Admin' },
  { value: ContractClass.H, label: 'Hedge' },
  { value: ContractClass.NH, label: 'No Hedge' },
  { value: ContractClass.QP, label: 'QP Hedge' },
];

/**
 * CONTRACT_STATUS
 */
export enum ContractStatus {
  BOOKING_REQUESTED = 21119010,
  CANCELLED = 21119107,
  CONFIRMED = 21119004,
  DESTINATION_REQUESTED = 21118921,
  NEW = 1,
  ON_HOLD = 21119024,
  PENDING_CCIC = 21119176,
  PENDING_RATE = 21119003,
  RATES_EXPIRED = 21119015,
  TO_CORRECT = 119986,
  VERIFIED = 113120,
}

export type BasePhysicalContract = {
  id: number;
  companyId: number;
  number: number;
  incotermId: number;
  currencyId: number;
  counterpartyId: number;
  priceUnitId: number;
  productId: number;
  quantityUnitId: number;
  paymentTermId: number;
  shipmentPeriodStartMonth: number;
  shipmentPeriodType: ShipmentPeriodTypes;
  destinationId: number;
  type: ContractType;
  class: ContractClass;
  date: Date;
  quantity: number;
  shipmentPeriodStart: Date;
  shipmentPeriodEnd: Date;
  tolerance: number;
  itemId: number;
  originCountryId: number;
  associationId: Associations;
  metalControlGroupId: number | null;
  priceTermId: number | null;
  oldContractNumber: string;
  incotermPlaceId: number;
  archived: YN;
  archivingDate: Date | null;
  modifiedBy: string;
  modifyDate: Date;
  versionNb: number;
  counterpartyReference: string;
  costPrice: number;
  premium: number;
  fullPremium: number;
  premiumUnitId: number | null;
  statusId: ContractStatus;
  traderId: number;
  cancelled: 1 | null;
  hComment: number | null;
  principalLangKey: Languages.ENGLISH;
  price: number;
  createdBy: number;
  creationDate: Date;
  updatedBy: number | null;
  updateDate: Date | null;
  clientItemId: number;
  expectedNumberOfLoads: number;
  advanceType: AdvanceTypes | null;
  advanceValue: number | null;
  advanceAmount?: number | null;
  multipleQuality: YN;
  multipleOrigin: YN;
  multipleDestination: YN;
  multiplePrice: YN;
  multipleAssociation: YN;
  multipleIncoterm: YN;
  multipleCustomStatus: YN;
  multiplePeriod: YN;
  multipleMarkets: YN;
  shipmentLock: YN | null;
  collateral: number | null;
  estimatedMargin: number | null;
};

export type PhysicalContract = BasePhysicalContract & {
  clauses?: Clause[];
  lines?: ContractLine[];
  keywords?: ContractKeyword[];
  incoterm?: Incoterm;
  paymentTerm?: PaymentTerm;
  priceUnitFactor?: UnitInfo;
  quantityUnit?: Unit;
  premiumUnit?: Unit;
  product?: Product;
  counterparty?: Contact;
  trader?: Contact;
  company?: Contact;
  priceUnit?: Unit;
  currency?: Currency;
  comments?: Comment[];
  approvals?: Approval[];
  expenses?: PhysicalContractExpense[];
};

export type ContractLineRestrictions = Pick<ContractLine, 'id' | 'lineNumber'> & {
  hasShipments: boolean;
  hasInvoices: boolean;
  hasFixations: boolean;
};

export type ContractRestrictions = Pick<PhysicalContract, 'id'> & {
  hasShipments: boolean;
  hasFixations: boolean;
  hasInvoices: boolean;
  hasAdvances: boolean;
  lines: ContractLineRestrictions[];
};

export enum ClauseTypes {
  GENERAL = 120193,
  LC = 14902012,
}

export enum ShipmentPeriodTypes {
  DELIVERY = 124028,
  SHIPMENT = 100106,
}

/**
 * QUOTATION_PERIOD
 */
export enum PricingQualifier {
  MONTHLY_AVERAGE = 100117,
  DATE_INTERVAL = 100118,
  UNKNOWN = 100119,
}

export enum MarketCotation {
  NONE = 0,
  LOW = 1,
  HIGH = 2,
  MEAN = 3,
  SELLER_ASK = 4,
  BUYER_BID = 5,
  MID = 6,
}

export enum PhysicalPricingType {
  PRICE = 1,
  PREMIUM = 2,
  FX_ALL = 6,
}

export enum KeywordTypes {
  CLIENT_OVERRIDE_QUAL = 121677,
  COMM_COMPLETE = 6697189,
  MWTI_USED = 17012550,
  LC_INTEREST_INCLUDED = 18015277,
}

type Buffer = any;

export interface ContractKeyword {
  id: number;
  contractId: number;
  keywordType: KeywordTypes;
  keywordValue: string;
  keywordBlobValue: Buffer;
  contract?: PhysicalContract;
}
export type CreateContractKeywordRequest = Pick<ContractKeyword, 'keywordType' | 'keywordValue'>;
export type UpdateContractKeywordRequest = Pick<ContractKeyword, 'id'> & Partial<Pick<ContractKeyword, 'keywordValue' | 'keywordType'>>;

export type BasePhysicalContractRequest = Pick<
  BasePhysicalContract,
  | 'companyId'
  | 'incotermId'
  | 'currencyId'
  | 'counterpartyId'
  | 'priceUnitId'
  | 'productId'
  | 'quantityUnitId'
  | 'paymentTermId'
  | 'type'
  | 'class'
  | 'date'
  | 'tolerance'
  | 'archived'
  | 'premiumUnitId'
  | 'traderId'
  | 'estimatedMargin'
  | 'expectedNumberOfLoads'
  | 'advanceType'
  | 'advanceValue'
  | 'counterpartyReference'
  | 'shipmentLock'
  | 'collateral'
>;
export type CreatePhysicalContractRequest = BasePhysicalContractRequest & {
  approvals?: CreateApprovalRequest[];
  lines?: CreateContractLineRequest[];
  clauses: (Pick<Clause, 'clauseTemplateId' | 'type'> & Partial<Pick<Clause, 'text'>>)[];
  expenses: Pick<PhysicalContractExpense, 'budgetElementId' | 'contactId' | 'currencyId' | 'byPacking' | 'amount' | 'mandatory' | 'userAdded' | 'notes'>[];
};
export type UpdatePhysicalContractRequest = Partial<BasePhysicalContractRequest> &
  Pick<BasePhysicalContract, 'id'> & {
    approvals?: (CreateApprovalRequest | UpdateApprovalRequest)[];
    lines?: (CreateContractLineRequest | UpdateContractLineRequest)[];
    expenses?: any[];
    clauses?: any[];
    keywords?: (UpdateContractKeywordRequest | CreateContractKeywordRequest)[];
  };

export type ClauseMissingInformationEmailRequest = {
  contractNumber: number;
  emailTo: string;
  message: string;
};

export type ContractForHegde = Pick<PhysicalContract, 'id' | 'number' | 'quantity' | 'quantityUnitId' | 'type' | 'companyId'> & {
  metalUnitPercentage: number | null;
  item?: string;
  quantityInMts?: number;
};

export type LinesForNP = {
  line: ContractLine;
  item?: ArticleText;
  shipments: PropertyDocument[];
  fixations: PcPriceFixing[];
};

export type ContractForNP = Pick<PhysicalContract, 'id' | 'number' | 'quantity' | 'quantityUnitId' | 'type' | 'companyId'> & {
  lines: LinesForNP[];
};
