import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogRef, DialogResult, DialogService, WindowCloseResult } from '@progress/kendo-angular-dialog';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { isObservable, merge, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DynamicFormComponent, FormElementConfig } from 'src/app/shared/dynamic-form/dynamic-form.component';
import { ModalWindowComponent } from 'src/app/shared/modal-window/modal-window.component';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { Store } from './store.service';
import { endpoints as endpointsType } from 'src/lib/apiEndpoints';
import { FormControlStatus, TypedFormGroup } from 'src/lib/typedForms';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SelectorPopupService implements OnDestroy {
  authorized: endpointsAuthorized;
  constructor(private dialogService: DialogService, private router: Router, store: Store, private hotkeys: HotkeysService) {
    endpointAuthorizationSubscription(store, this);
  }

  ngOnDestroy() {}

  openStatic<T>(content: IComponent<T>, options: baseModalOptions<any, T>) {
    let title = options.title;
    if (content?.prototype?.thalos_permissions) {
      let endpoints: endpointsType[] = content.prototype.thalos_permissions;
      if (!endpoints.every((e) => this.authorized[e])) {
        this.dialogService.open({
          title: 'Missing permissions',
          content: `You are not authorized to use this feature - ${content.prototype.thalos_name ?? 'Misc Popup'}`,
        });
        console.warn('Missing permissions for popup', ...endpoints.filter((e) => !this.authorized[e]));
        return of('Close');
      }
    }
    let dialog = this.dialogService.open({
      content,
      title,
      width: options.width,
      height: options.height,
      maxHeight: '90%',
      maxWidth: '95%',
    });

    const escapeToClose = new Hotkey(
      ['escape'],
      () => {
        if (document.activeElement != document.body) (document.activeElement as HTMLElement).blur();
        dialog.close();
        return false;
      },
      ['input', 'textarea', 'select']
    );

    this.hotkeys.add(escapeToClose);

    let component: T = dialog.content.instance;

    if (options.initializer) {
      options.initializer(component);
    }

    let routeChange = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!closed && !!dialog) {
        this.hotkeys.remove(escapeToClose);
        dialog.close();
        routeChange.unsubscribe();
      }
    });
    let closed = false;
    return dialog.result.pipe(
      tap((_) => {
        this.hotkeys.remove(escapeToClose);
        closed = true;
        routeChange.unsubscribe();
      })
    );
  }

  open<T, C extends SelectorComponent<T>>(content: IClass<T, C>, options: SelectorOptions<T, C>): Observable<SelectorResult<T>> {
    let title = options.title;
    if (content?.prototype?.thalos_permissions) {
      let endpoints: endpointsType[] = content.prototype.thalos_permissions;
      if (!endpoints.every((e) => this.authorized[e])) {
        this.dialogService.open({
          title: 'Missing permissions',
          content: `You are not authorized to use this feature - ${content.prototype.thalos_name ?? 'Misc Popup'}`,
        });
        console.warn('Missing permissions for popup', ...endpoints.filter((e) => !this.authorized[e]));
        return of('Close');
      }
    }
    let dialog = this.dialogService.open({
      content,
      title,
      actions: options.alwaysSelect
        ? undefined
        : [
            {
              text: 'Cancel',
            },
            {
              text: 'Select',
              themeColor: 'primary',
              primary: true,
            },
          ],
      width: options.width || '95%',
      height: options.height || '90%',
      maxWidth: '95%',
      maxHeight: '90%',
    });

    let component: C = dialog.content.instance;
    component.popup = true;
    component.selectorApi = {
      close: () => {
        dialog.close();
      },
    };

    if (options.preselectedItems) {
      component.preselectItems(options.preselectedItems);
    }
    if (options.initializer) {
      options.initializer(component);
    }

    let routeChange = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!closed && !!dialog) {
        dialog.close();
        routeChange.unsubscribe();
      }
    });
    let closed = false;

    return dialog.result.pipe(
      take(1),
      withLatestFrom(component.popupObservable.pipe(startWith(null))),
      tap(() => {
        closed = true;
        routeChange.unsubscribe();
      }),
      map(([dialogRes, selected]: [CustomDialogResult, T]) => {
        if ((!(dialogRes instanceof DialogCloseResult) && dialogRes.primary) || options.alwaysSelect) {
          return selected;
        }
        if (dialogRes instanceof DialogCloseResult && options.returnData && selected) {
          return selected;
        }
        return 'Close';
      })
    );
  }

  openFormWindow<T, C extends ModalFormComponent<T>>(content: IClass<T, C>, options: ModalFormOptions<T, C>, actions: WindowAction<T, C>[] = []): Observable<SelectorResult<T>> {
    let title = options.title;
    let dialog = this.dialogService.open({
      title,
      content: ModalWindowComponent,
      maxHeight: options.maxHeight || options.height,
      maxWidth: options.maxWidth || options.width,
      width: options.width,
      height: options.height,
    });
    dialog.result.subscribe();

    let modal: ModalWindowComponent<T, C> = dialog.content.instance;
    let component: C;
    modal.loadWindow(content, actions).subscribe((c: C) => {
      component = c;

      component.popup = true;

      if (options.prefillValue) {
        component.prefillForm(options.prefillValue);
      }
      if (options.initializer) {
        options.initializer(component);
      }
    });

    const routeChange = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!closed && !!dialog) {
        dialog.close();
        routeChange.unsubscribe();
      }
    });
    let closed = false;
    return merge(dialog.result, modal.result).pipe(
      switchMap((res: WindowCloseResult | WindowAction<T, C>): Observable<SelectorResult<T>> => {
        if (isWindowAction(res)) {
          let actionResult = res.action(component);
          if (isObservable(actionResult)) return actionResult;
          else return of(actionResult);
        } else {
          return of('Close');
        }
      }),
      tap((res) => {
        if (res !== null) dialog.close();
      }),
      filter((res) => res !== null)
    );
  }

  openForm<SubmitType, C extends ModalFormComponent<SubmitType, PrefillType>, PrefillType = SubmitType>(
    content: IClass<SubmitType, C, PrefillType>,
    options: ModalFormOptions<PrefillType, C>,
    actions: { name: string; themeColor?: string; action: (c: C) => SubmitType | Observable<SubmitType> }[] = []
  ): Observable<SelectorResult<SubmitType>> {
    let title = options.title;
    let dialog = this.dialogService.open({
      content,
      title,
      actions: [
        ...(options.hideDefaultActions
          ? []
          : [
              {
                text: 'Cancel',
              },
              {
                text: options.submitButtonText || 'Submit',
                themeColor: 'primary',
                primary: true,
              },
            ]),
        ...actions.map((a) => {
          return {
            text: a.name,
            themeColor: a.themeColor || 'primary',
            primary: true,
          };
        }),
      ],
      preventAction: (ev: CustomDialogResult, dialogRef: DialogRef) => {
        if ((ev instanceof DialogCloseResult || !ev.primary) && !options.preventClose) {
          return false;
        } else if ((ev instanceof DialogCloseResult || !ev.primary) && options.preventClose) {
          return true;
        } else {
          const component: C & { form?: TypedFormGroup<SubmitType> } = dialogRef.content.instance;
          const form = component?.form;
          const isFormValid = form?.status === FormControlStatus.VALID;
          const canSubmit = component?.allowSubmit();
          if ((isFormValid && canSubmit) || (!form && canSubmit)) return false;
          return true;
        }
      },
      maxHeight: options.maxHeight || options.height,
      maxWidth: options.maxWidth || options.width,
      minWidth: options.minWidth || undefined,
      width: options.width,
      height: options.height,
    });

    let component: C = dialog.content.instance;
    component.popup = true;
    component.selectorApi = {
      close: () => {
        dialog.close();
      },
    };

    if (options.prefillValue) {
      component.prefillForm(options.prefillValue);
    }
    if (options.initializer) {
      options.initializer(component);
    }

    let routeChange = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!closed && !!dialog) {
        dialog.close();
        routeChange.unsubscribe();
      }
    });
    let closed = false;
    return dialog.result.pipe(
      switchMap((dialogResult: CustomDialogResult): Observable<SelectorResult<SubmitType>> => {
        closed = true;
        routeChange.unsubscribe();
        if (dialogResult instanceof DialogCloseResult || !dialogResult.primary) {
          return of('Close');
        }
        let matchingAction = actions.find((a) => a.name === dialogResult.text);
        if (!matchingAction) {
          let res = component.submit();
          if (isObservable(res)) return res;
          else return of(res);
        } else {
          let res = matchingAction.action(component);
          if (isObservable(res)) return res;
          else return of(res);
        }
      })
    );
  }

  //dynamicForm<T>(title: string, prefill: Partial<T>, width: number, ...elements: (FormElementConfig<T> | FormElementConfig<T>[])[]) {
  dynamicForm<SubmitType, PrefillType = SubmitType>(title: string, prefill: PrefillType, width: number, ...elements: (FormElementConfig<PrefillType> | FormElementConfig<PrefillType>[])[]) {
    return this.openForm<SubmitType, DynamicFormComponent<PrefillType>, PrefillType>(DynamicFormComponent, {
      title,
      maxHeight: '90%',
      maxWidth: '90%',
      width: width,
      prefillValue: prefill,
      initializer: (c) => {
        c.formElements = elements;
      },
    });
  }
}

export interface SelectorComponent<T, K = T> {
  popup: boolean;
  popupObservable: Observable<T>;
  preselectItems: (items: K) => void;
  selectorApi?: SelectorApi;
}

export interface ModalFormComponent<SubmitType, PrefillType = SubmitType> {
  popup: boolean;
  //prefillForm: (data: Partial<PrefillType>) => void
  prefillForm: (data: PrefillType) => void;
  allowSubmit: () => boolean | Promise<boolean>;
  submit: () => SubmitType | Observable<SubmitType>;
  selectorApi?: SelectorApi;
}

export interface IClass<SubmitType, C extends SelectorComponent<SubmitType, PrefillType> | ModalFormComponent<SubmitType, PrefillType>, PrefillType = SubmitType> {
  new (...any): C;
}

export interface IComponent<C> {
  new (...any): C;
}

export type SelectorResult<T> = 'Close' | T | null;

type baseModalOptions<T, C> = {
  title: string;
  initializer?: (c: C) => void;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  height?: number | string;
  submitButtonText?: string;
};

type SelectorOptions<T, C> = baseModalOptions<T, C> & {
  preselectedItems?: T;
  alwaysSelect?: boolean;
  returnData?: boolean;
};

type ModalFormOptions<PrefillType, C> = baseModalOptions<PrefillType, C> & {
  //prefillValue?: Partial<PrefillType>
  prefillValue?: PrefillType;
  preventClose?: boolean;
  hideDefaultActions?: boolean;
};

export type WindowAction<T, C> = {
  name: string;
  action: (c: C) => Observable<T | null> | T | null;
  cssClass?: any;
  closeOnNullReturn?: boolean;
  canClick?: (c: C) => Observable<boolean> | boolean;
};

function isWindowAction<T, C>(res: WindowCloseResult | WindowAction<T, C>): res is WindowAction<T, C> {
  return !!(res as WindowAction<T, C>)?.name && !!(res as WindowAction<T, C>)?.action;
}

export type SelectorApi = {
  close: () => void;
};

export type CustomDialogResult = DialogResult & {
  primary?: boolean;
};
