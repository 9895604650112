import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DuplicateContractLineForm } from 'src/app/+modules/+trading/containers/base-contract/base-contract.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModalFormComponent, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ContractLineEditorComponent } from '../contract-line-editor/contract-line-editor.component';
import { endpointAuthorizationSubscription } from 'src/lib/helperFunctions';
import { minValidator } from 'src/lib/genericValidators';

@UntilDestroy()
@Component({
  selector: 'duplicate-contract-line',
  templateUrl: './duplicate-contract-line.component.html',
  styleUrls: ['./duplicate-contract-line.component.scss'],
})
export class DuplicateContractLineComponent extends ContractLineEditorComponent implements OnInit, OnDestroy, ModalFormComponent<DuplicateContractLineForm> {
  constructor(store: Store, selectorService: SelectorPopupService, spinnerService: SpinnerService, notificationService: NotificationService, api: ThalosApiService) {
    super(store, selectorService, spinnerService, notificationService, api);
    endpointAuthorizationSubscription(store, this);
    this.addNumberOfLoadsField();
    this.makeIdOptional();
  }

  private addNumberOfLoadsField() {
    this.form.addControl('numberOfLoadsAdded', new UntypedFormControl(null, [Validators.required, minValidator(0, true)]));
  }

  private makeIdOptional() {
    this.form.removeControl('id');
  }
}
