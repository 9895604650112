import { of } from 'rxjs';
import { DuplicateContractLineForm, getBaseLineRequest } from 'src/app/+modules/+trading/containers/base-contract/base-contract.component';
import { endpoints } from 'src/lib/apiEndpoints';
import {
  ContractClass,
  ContractFormPricingToContractPricing,
  ContractPricingToContractFormPricing,
  CreateContractLineRequest,
  PhysicalContract,
  SourceEntityType,
  UpdatePhysicalContractRequest,
} from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, submitFormCallback } from './types';
import { contractLineExecPrefill, getContractLineFormData } from './updateContractLine';
import { DuplicateContractLineComponent } from 'src/app/shared/duplicate-contract-line/duplicate-contract-line.component';

export const openDuplicateContractLineForm: openFormCallback<PhysicalContract, DuplicateContractLineForm> = (delegate, id, prefill) => {
  if (!prefill || !prefill.lines || !prefill.lines?.length) return of('Close');

  const { line, quantityUnit, priceUnit, currency, premiumUnit, premiumCurrency, product, incoterm } = getContractLineFormData(delegate, id, prefill);

  if (!line) return of('Close');

  const selector = delegate.getService('selector');

  return selector.openForm<DuplicateContractLineForm, DuplicateContractLineComponent, Partial<DuplicateContractLineForm>>(DuplicateContractLineComponent, {
    width: '90%',
    title: 'Duplicate Line',
    prefillValue: {
      ...line,
      pricing: ContractPricingToContractFormPricing(line),
    },
    initializer: (editor) => {
      editor.quantityUnit = quantityUnit;
      editor.priceUnit = priceUnit;
      editor.currency = currency;
      editor.premiumUnit = premiumUnit;
      editor.premiumCurrency = premiumCurrency;
      editor.product = product;
      editor.contractClass = prefill.class;
      editor.contractDate = prefill.date ? new Date(prefill.date) : null;
      editor.incoterm = incoterm;
    },
  });
};

export const submitLineForm: submitFormCallback<PhysicalContract, DuplicateContractLineForm> = (delegate, id, line, contract) => {
  // We take the current Contract expectedNumberOfLoads + Number of Loads added
  const totalNumberOfLoads = contract.expectedNumberOfLoads + line.numberOfLoadsAdded;

  const request: UpdatePhysicalContractRequest = {
    id: contract.id,
    expectedNumberOfLoads: totalNumberOfLoads,
    lines: [
      {
        ...getBaseLineRequest(line),
        metalPercentage: contract.class === ContractClass.QP || contract.class === ContractClass.H ? line.metalPercentage : 100,
        ...ContractFormPricingToContractPricing(line.pricing, contract.class),
      } as CreateContractLineRequest,
    ],
  };

  const api = delegate.getService('api');

  return api.rpc<PhysicalContract>(endpoints.updateContractManagement, request, null);
};

export const duplicateLinePreset: DynamicFormConstant<PhysicalContract, DuplicateContractLineForm> = {
  value: DynamicFormType.DUPLICATE_CONTRACT_LINE,
  label: 'Duplicate Contract Line',
  title: 'Duplicate Line',
  allowMultipleRows: false,
  endpoints: [endpoints.getContract, endpoints.updateContractManagement],
  width: 0,
  getPrefill: contractLineExecPrefill,
  openForm: openDuplicateContractLineForm,
  submitForm: submitLineForm,
  entityType: SourceEntityType.CONTRACT_LINE_ID,
};
