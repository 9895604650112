import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { ColumnApi, GridApi, GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import { Observable, of, Subscriber } from 'rxjs';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { CustomDialogResult, SelectorApi, SelectorComponent, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib';
import {
  dateColumn,
  defaultComplexGrid,
  defaultListComplexGrid,
  entityLinkDoubleClick,
  enumLabelFormatter,
  enumValueGetter,
  getContextMenuItems,
  gotoMenu,
  gotoMenuItem,
  gridDateGetter,
  selectionColumn,
} from 'src/lib/agGridFunctions';
import { endpoints } from 'src/lib/apiEndpoints';
import { Permissions } from 'src/lib/componentPermissions';
import { QueryFilters } from 'src/lib/generics';
import { endpointAuthorizationSubscription, endpointsAuthorized, getTodayUTC, markFormGroupTouched, toBradyUTCDate } from 'src/lib/helperFunctions';
import { IRoutable } from 'src/lib/isRoutable';
import {
  CommonCurrencies,
  ContainerType,
  CustomRateFinderLine,
  OceanRateLookupData,
  OceanRateSegmentForm,
  SourceEntityType,
  UserGroup,
  getOceanRateFinderCustomData,
  rateSizes,
} from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';
import { UserGroupsEnum, randomFetchSynonym } from 'src/lib/uiConstants';
import { _fe } from '../dynamic-form/dynamic-form.component';
import { basicGraphqlCityDropdown } from 'src/lib/graphql/graphQlFunctions';

@UntilDestroy()
@Component({
  selector: 'thalos-rate-finder',
  templateUrl: './rate-finder.component.html',
})
@Permissions('Rate Finder', [endpoints.listOceanRates])
export class RateFinderComponent implements OnInit, OnDestroy, SelectorComponent<OceanRateLookupData>, IRoutable {
  popup = false;
  popupObservable: Observable<OceanRateLookupData>;
  popupObserver: Subscriber<OceanRateLookupData | RateRequestedResponse>;
  selectorApi: SelectorApi;

  filterForm: TypedFormGroup<RateFinderForm>;

  gridOptions: GridOptions;

  data: OceanRateLookupData[] = [];

  today: number;
  rateRequested = false;

  cityDropdown = basicGraphqlCityDropdown();

  authorized: endpointsAuthorized;

  get createRateAuthorized() {
    return this.authorized[endpoints.createOceanRateJob];
  }

  get updateRateAuthorized() {
    return this.authorized[endpoints.updateOceanRate];
  }

  get requestRateAuthorized() {
    return this.authorized[endpoints.requestOceanRate];
  }

  readonly: boolean;

  currentUserGroup: UserGroup;

  @Input()
  readonlyDestination: boolean = false;

  constructor(
    private api: ThalosApiService,
    private spinnerService: SpinnerService,
    formatter: DataFormattingService,
    private selectorService: SelectorPopupService,
    store: Store,
    private dialogService: DialogService,
    private delegate: DelegateService,
    private router: Router
  ) {
    this.filterForm = new TypedFormGroup<RateFinderForm>({
      origin: new UntypedFormControl(null, Validators.required),
      destination: new UntypedFormControl(null, Validators.required),
    });

    this.currentUserGroup = store.snapshot((state) => state.user.userGroups).find((group) => group.cn === UserGroupsEnum.DIT || group.cn === UserGroupsEnum.OCEAN_RATES_OPERATOR);

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });

    this.today = toBradyUTCDate(getTodayUTC());
    const green = 'rgba(0,255,0,0.25)';
    const red = 'rgba(255,0,0,0.25)';
    const blue = 'rgba(34,147,245,0.25)';

    this.gridOptions = {
      ...defaultComplexGrid(this.delegate, 'segmentId'),
      ...defaultListComplexGrid(this.delegate),
      autoGroupColumnDef: {
        headerName: 'Size',
        filter: 'agGroupColumnFilter',
        filterValueGetter: enumValueGetter('size', rateSizes),
      },
      suppressCopyRowsToClipboard: true,
      groupIncludeFooter: false,
      groupIncludeTotalFooter: false,
      rowSelection: 'single',
      getRowStyle: (params) => {
        return params.node.group
          ? { 'background-color': 'initial' }
          : params.data?.expirationDate < this.today
          ? { 'background-color': red }
          : params.data?.effectiveDate > this.today
          ? { 'background-color': blue }
          : { 'background-color': green };
      },
      columnDefs: [
        { ...selectionColumn(() => this.popup && !this.readonly) },
        { field: 'rateId', hide: true },
        { field: 'shippingAndFreightNames', headerName: 'Shipping Line (Freight Forwarder)', width: 300 },
        { field: 'originAndPOLNames', headerName: 'Origin (Port of Loading)', width: 270 },
        { field: 'pODAndDestinationNames', headerName: 'Port of Discharge (Destination)', width: 270 },
        {
          field: 'size',
          valueFormatter: enumLabelFormatter(rateSizes),
          width: 80,
          rowGroup: true,
          hide: true,
          showRowGroup: false,
        },
        { ...dateColumn('effectiveDate', 'Effective') },
        {
          ...dateColumn('expirationAndEfectiveDates', 'Expiration (Effective)'),
          valueFormatter: undefined,
          filterValueGetter: gridDateGetter('expirationDate'),
          width: 150,
        },
        {
          field: 'totalCost',
          headerName: 'Total',
          valueFormatter: formatter.gridStaticCurrencyFormatter(CommonCurrencies.USD),
          sort: 'asc',
          cellStyle: { 'text-align': 'right' },
        },
        { field: 'agreement', headerName: 'Agreement Number' },
        { field: 'freeDaysOriginAndDestination', headerName: 'Free Days Origin/Destination', width: 150, cellStyle: { 'text-align': 'center' } },
        { field: 'transitTime', headerName: 'Transit Time', width: 110 },
        { field: 'notes', width: 300 },
      ],
      getContextMenuItems: getContextMenuItems(gotoMenu(gotoMenuItem(this.delegate, 'Rate', 'rateId', SourceEntityType.RATE_KEY, 'get', 'rateId'))),
      onSelectionChanged: this.onSelectionChanged(),
      onRowDoubleClicked: entityLinkDoubleClick('rateId', 'portal/logistics/ocean-rates/rates', this.router, () => this.updateRateAuthorized),
    };

    endpointAuthorizationSubscription(store, this);

    this.readonly = false;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onSelectionChanged() {
    return (params: SelectionChangedEvent) => {
      const nodes = params.api.getSelectedNodes();
      const dialogService = this.delegate.getService('dialog');

      if (!nodes || nodes.length === 0) this.popupObserver.next(null);
      else {
        const node: OceanRateLookupData = nodes[0].data;
        if (node.expirationDate < this.today) {
          const dialog = dialogService.open({
            title: 'Expired Ocean Rate',
            content: `Selected ocean rate is expired, please request a new one.`,
            actions: [
              {
                text: 'Cancel',
              },
              {
                text: 'Request Rate',
                themeColor: 'primary',
                primary: true,
              },
            ],
          });

          dialog.result.subscribe((res: CustomDialogResult) => {
            if (res instanceof DialogCloseResult || !res.primary) return of('Close');
            else this.requestOceanRate();
          });
        }
        if (node && this.popupObserver) this.popupObserver.next(node);
      }
    };
  }

  preselectItems(rate) {}

  setExistingRate(rate: OceanRateLookupData) {
    if (rate) this.data = [rate];
  }

  clickSearch() {
    markFormGroupTouched(this.filterForm);
    if (!this.filterForm.valid) return;

    const filters: QueryFilters<OceanRateLookupData> = {
      originId: this.filterForm.value.origin.id,
      destinationId: this.filterForm.value.destination.id,
    };
    const rid = this.spinnerService.startRequest(randomFetchSynonym() + ' Rates');
    this.api.rpc<ListResponse<OceanRateLookupData>>(endpoints.oceanRateLookup, filters, { list: [], count: 0 }).subscribe((res) => {
      const newData: (OceanRateLookupData & CustomRateFinderLine)[] = [];
      const segments: OceanRateLookupData[] = res.list;
      for (const segment of segments) {
        const customsSegmentData = getOceanRateFinderCustomData(segment);
        newData.push({ ...segment, ...customsSegmentData });
      }
      this.data = newData;
      this.spinnerService.completeRequest(rid);
    });
  }

  onGridReady(ev: { api: GridApi; columnApi: ColumnApi }) {
    if (this.data.length === 1) {
      ev.api.getRowNode(`${this.data[0].segmentId}`)?.selectThisNode(true);
    }

    if (this.readonly || !this.popup) {
      ev.columnApi.setColumnVisible('checkbox', false);
    }
  }

  createOceanRate() {
    const entityLookup = this.delegate.getService('entityLookup');
    const newEntityPath = entityLookup.getLink(SourceEntityType.RATE_KEY, 'create');
    window.open(newEntityPath);
  }

  requestOceanRate() {
    this.selectorService
      .dynamicForm<Partial<RateRequestForm>>(
        'Request Rate',
        { ...this.filterForm.value },
        500,
        [_fe('origin', 'Origin', this.cityDropdown, null, Validators.required, false, true), _fe('destination', 'Destination', this.cityDropdown, null, Validators.required, false, true)],
        [
          _fe(
            'sizes',
            'Size',
            {
              listProcedure: endpoints.listContainerTypes,
              valueField: 'id',
              labelField: 'name',
              array: true,
            },
            [],
            Validators.required,
            false,
            true
          ),
          _fe('targetPrice', 'Target Price', 'Text', '', undefined, false, true),
        ],
        _fe('comments', 'Comments', 'TextArea', '', undefined, false, true)
      )
      .subscribe((res) => {
        if (res !== 'Close') {
          const rid = this.spinnerService.startRequest('Requesting');
          this.api
            .rpc<any>(
              endpoints.requestOceanRate,
              {
                requestOrigin: res.origin.id,
                requestDestination: res.destination.id,
                comments: res.comments,
                targetPrice: res.targetPrice,
                sizes: res.sizes.map((s) => s.id),
              },
              null
            )
            .subscribe((response) => {
              this.spinnerService.completeRequest(rid);
              if (response) {
                this.rateRequested = true;
                this.popupObserver.next({ rateRequested: this.rateRequested, destination: res.destination.name });
                this.dialogService.open({
                  title: 'Success',
                  content: 'Request sent successfully',
                });
                this.selectorApi.close();
              }
            });
        }
      });
  }

  /**
   * Clears local filters, quick filter, and column size/order of the grid.
   */
  resetFilters() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setFilterModel({});
    }
    this.filterForm.patchValue({
      origin: null,
      destination: null,
    });
  }

  openRateRequestEmail(type: 'EN' | 'ES' | 'Truck') {
    const origin = this.filterForm.get('origin').value?.name ?? '';
    const destination = this.filterForm.get('destination').value?.name ?? '';
    const lineBreak = '%0D%0A';
    let body: string = '';
    let subject: string = '';
    if (type === 'EN') {
      subject = 'Rate Request';
      body = `Hello,${lineBreak}${lineBreak}I hope that you are doing well. Please quote me for the following segment:${lineBreak}${lineBreak}Origin: ${origin}${lineBreak}Destination: ${destination}${lineBreak}Material: Aluminum, Copper or HMS Scrap${lineBreak}Container Type: 20'ST / 40' ST / 40'HC${lineBreak}Commodity: Scrap Metal${lineBreak}Expected Volume:${lineBreak}${lineBreak}Please provide reference or quote # for booking purposes, allocate al least 14 days of free time at destination, and advise the expiration date of the rate.${lineBreak}${lineBreak}Let me know if you need more information.${lineBreak}${lineBreak}Thank you.`;
    } else if (type === 'ES') {
      subject = 'Solicitud de Tarifa';
      body = `Hola,${lineBreak}${lineBreak}Espero que esté bien. Por favor cotíceme el siguiente segmento:${lineBreak}${lineBreak}Origen: ${origin}${lineBreak}Destino: ${destination}${lineBreak}Material: Aluminio, Cobre o Chatarra HMS${lineBreak}Tipo de Contenedor: 20'ST / 40' ST / 40'HC${lineBreak}Producto: Chatarra${lineBreak}Volumen Esperado:${lineBreak}${lineBreak}Por favor, proporcione la referencia o el número de cotización para fines de la reserva, asigne al menos 14 días de tiempo libre en el destino e informe la fecha de vencimiento de la tarifa.${lineBreak}${lineBreak}Déjeme saber si necesita más información.${lineBreak}${lineBreak}Gracias.`;
    } else {
      subject = 'Rate Request';
      body = `Hello,${lineBreak}${lineBreak}I hope that you are doing well. Please quote me for the following lane:${lineBreak}${lineBreak}Origin: ${origin}${lineBreak}Destination: ${destination}${lineBreak}Material: Metal Scrap${lineBreak}Container Type: 53' Truck or Van${lineBreak}${lineBreak}Let me know if you need more information.${lineBreak}${lineBreak}Thank you.`;
    }
    window.open(`mailto:?subject=${subject}&body=${body}`, '_self');
  }

  get userEmailAuthorized() {
    return this.currentUserGroup !== undefined;
  }

  getTabTitle() {
    return 'Ocean Rates';
  }
}

export type RateFinderForm = Pick<OceanRateSegmentForm, 'destination' | 'origin'>;

export type RateRequestForm = Pick<OceanRateSegmentForm, 'destination' | 'origin'> & {
  sizes: ContainerType[];
  comments: string;
  targetPrice: string;
};

export type RateRequestedResponse = { rateRequested: boolean; destination: string };
