import { DialogResult } from '@progress/kendo-angular-dialog';
import { round } from 'lodash';
import { from } from 'rxjs';
import { UpdatePriceFixationComponent } from 'src/app/+modules/+hedging/update-price-fixation/update-price-fixation.component';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { getTodayUTC, toBradyUTCDate } from 'src/lib/helperFunctions';
import { Contact, ContractType, PhysicalContract, SourceEntityType } from 'src/lib/newBackendTypes';
import { ContractLineFixationData } from 'src/lib/newBackendTypes/contractLineFixationData';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import { ApplyResponse, ApplyUnapplyPriceFixationRequest, FixationSendEmailOption, PcPriceFixing, UnapplyResponse, UpdatePriceFixationRequest } from 'src/lib/newBackendTypes/priceFixation';
import { ShipmentFixations } from 'src/lib/newBackendTypes/shipmentFixations';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';

const updatePriceFixationPrefill: prefillCallback<UpdatePriceFixationPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;

      const priceFixation = await api.run<PriceFixationData>(endpoints.getPriceFixation, { filters: { id } });
      const currencyConversions = await api.run<ExchangeRateInfo[]>(endpoints.getDailyExchangeRates, { date: toBradyUTCDate(getTodayUTC()) });

      const shipmentsResponse = await api.run<ListResponse<ShipmentFixations>>(endpoints.listShipmentFixations, { filters: { elementKey: priceFixation.contractLine.id } });
      const shipments = shipmentsResponse.list;

      const contract = await api.run<PhysicalContract>(endpoints.getContract, { filters: { id: priceFixation.contractId } });
      const counterparty = contract.counterparty;

      return { priceFixation, shipments, currencyConversions, counterparty };
    })()
  );
};

const updatePriceFixationForm: openFormCallback<UpdatePriceFixationPrefill, UpdatePriceFixationForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<UpdatePriceFixationForm, UpdatePriceFixationComponent, UpdatePriceFixationPrefill>(UpdatePriceFixationComponent, {
    title: `Update Price Fixation For Contract: ${prefill ? prefill.priceFixation.contractLine.contract.number + '/' + prefill.priceFixation.elementNumber : 'Unknown'}`,
    prefillValue: prefill,
    maxWidth: '840px',
  });
};

const submitUpdatePriceFixation: submitFormCallback<UpdatePriceFixationPrefill, UpdatePriceFixationForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  const spinner = delegate.getService('spinner');
  const rid = spinner.startRequest('Submitting');
  const flexService = delegate.getService('flexService');

  return from(
    new Promise<DialogResult>((resolve, reject) => {
      (async () => {
        const request: UpdatePriceFixationRequest = {
          id: id,
          quantityFixed: result.quantityFixed,
          fixDate: result.fixDate,
          termPrice: result.termPrice,
          fixedContractPrice: result.fixedContractPrice,
          leftOverFutQtyComment: result.leftOverFutQtyComment,
          fixationRate: result.fixationRate,
          metalUnits: result.metalUnits,
          metalUnitsPercentage: result.metalUnitsPercentage,
        };
        const updateFixationResponse = await api.run<PcPriceFixing & { contractNumber: number; contractUnitAbbr: string }>(endpoints.updatePriceFixation, request, null);
        if (updateFixationResponse) return resolve(updateFixationResponse);
        return reject('Unknown result. Please check if the contract line fixation was updated and try again if necessary.');
      })();
    })
      .then(async (res: PcPriceFixing & { contractNumber: number; contractUnitAbbr: string }) => {
        const applyRequest: ApplyUnapplyPriceFixationRequest = {
          id: res.id,
          shipmentIdArr: result.applyShipmentsIds,
          metalUnits: result.metalUnits,
          metalUnitsPercentage: result.metalUnitsPercentage,
          sendEmail: result.sendEmail,
        };
        const unapplyRequest: ApplyUnapplyPriceFixationRequest = {
          id: res.id,
          shipmentIdArr: result.unapplyShipmentsIds,
          sendEmail: result.sendEmail,
        };
        if (result.applyShipmentsIds.length !== 0 && result.unapplyShipmentsIds.length !== 0) {
          const applyFixationResponse = await api.run<ListResponse<ApplyResponse>>(endpoints.applyPriceFixations, applyRequest, null);
          const unapplyFixationResponse = await api.run<ListResponse<UnapplyResponse>>(endpoints.unapplyPriceFixations, unapplyRequest, null);
          spinner.completeRequest(rid);
          flexService.emitDataReloadEvent();
          if (applyFixationResponse && applyFixationResponse.list && unapplyFixationResponse && unapplyFixationResponse.list) {
            const shipmentsApply = applyFixationResponse.list.map((list) => {
              if (!list.shipments) return null;
              return list.shipments.map((s) => `- ${s.id}`).join('\n');
            });
            const shipmentsUnapply = unapplyFixationResponse.list.map((list) => {
              if (!list.shipmentIdArr) return null;
              return list.shipmentIdArr.map((s) => `- ${s}`).join('\n');
            });
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nIt has been Applied to the following shipment(s): \n${
                shipmentsApply && shipmentsApply.length > 0 ? shipmentsApply.map((s) => `${s}`).join('\n') : 'Unknown'
              } \nAnd Unapplied from the following shipment(s): \n${shipmentsUnapply && shipmentsUnapply.length > 0 ? shipmentsUnapply.map((s) => `${s}`).join('\n') : 'Unknown'}</div>`
            );
          } else if (applyFixationResponse && applyFixationResponse.list) {
            const shipmentsApply = applyFixationResponse.list.map((list) => {
              if (!list.shipments) return null;
              return list.shipments.map((s) => `- ${s.id}`).join('\n');
            });
            return prompt.htmlDialog(
              'Partial Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nIt has been Applied to the following shipment(s): \n${
                shipmentsApply && shipmentsApply.length > 0 ? shipmentsApply.map((s) => `${s}`).join('\n') : 'Unknown'
              } \nAnd could NOT be Unapplied from the following shipment(s): \n${unapplyRequest.shipmentIdArr.map((s) => `- ${s}`).join('\n')}`
            );
          } else if (unapplyFixationResponse && unapplyFixationResponse.list) {
            const shipmentsUnapply = unapplyFixationResponse.list.map((list) => {
              if (!list.shipmentIdArr) return null;
              return list.shipmentIdArr.map((s) => `- ${s}`).join('\n');
            });
            return prompt.htmlDialog(
              'Partial Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nIt has been Unapplied from the following shipment(s): \n${
                shipmentsUnapply && shipmentsUnapply.length > 0 ? shipmentsUnapply.map((s) => `${s}`).join('\n') : 'Unknown'
              } \nAnd could NOT be Applied to the following shipment(s): \n${applyRequest.shipmentIdArr.map((s) => `- ${s}`).join('\n')}`
            );
          } else
            return prompt.htmlDialog(
              'Partial Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nIt could NOT be Applied to the following shipment(s): \n${applyRequest.shipmentIdArr
                .map((s) => `- ${s}`)
                .join('\n')} \nAnd could NOT be Unapplied from the following shipment(s): \n${unapplyRequest.shipmentIdArr.map((s) => `- ${s}`).join('\n')}`
            );
        }
        if (result.applyShipmentsIds.length !== 0) {
          const applyFixationResponse = await api.run<ListResponse<ApplyResponse>>(endpoints.applyPriceFixations, applyRequest, null);
          spinner.completeRequest(rid);
          flexService.emitDataReloadEvent();
          if (applyFixationResponse && applyFixationResponse.list) {
            const shipmentsApply = applyFixationResponse.list.map((list) => {
              if (!list.shipments) return null;
              return list.shipments.map((s) => `- ${s.id}`).join('\n');
            });
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nAnd has been Applied to the following shipment(s): \n${shipmentsApply && shipmentsApply.length > 0 ? shipmentsApply.map((s) => `${s}`).join('\n') : 'Unknown'}</div>`
            );
          } else
            return prompt.htmlDialog(
              'Partial Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nAnd could NOT be Applied to the following shipment(s): \n${applyRequest.shipmentIdArr.map((s) => `- ${s}`).join('\n')}</div>`
            );
        }
        if (result.unapplyShipmentsIds.length !== 0) {
          const unapplyFixationResponse = await api.run<ListResponse<UnapplyResponse>>(endpoints.unapplyPriceFixations, unapplyRequest, null);
          spinner.completeRequest(rid);
          flexService.emitDataReloadEvent();
          if (unapplyFixationResponse && unapplyFixationResponse.list) {
            const shipmentsUnapply = unapplyFixationResponse.list.map((list) => {
              if (!list.shipmentIdArr) return null;
              return list.shipmentIdArr.map((s) => `- ${s}`).join('\n');
            });
            return prompt.htmlDialog(
              'Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nAnd has been Unapplied from the following shipment(s): \n${shipmentsUnapply && shipmentsUnapply.length > 0 ? shipmentsUnapply.map((s) => `${s}`).join('\n') : 'Unknown'}</div>`
            );
          } else
            return prompt.htmlDialog(
              'Partial Success',
              `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
                res.contractUnitAbbr
              }). \nAnd could NOT be Unapplied from the following shipment(s): \n${unapplyRequest.shipmentIdArr.map((s) => `- ${s}`).join('\n')}</div>`
            );
        }
        spinner.completeRequest(rid);
        flexService.emitDataReloadEvent();
        return prompt.htmlDialog(
          'Success',
          `<div style="white-space: pre">The fixation for Contract ${res.contractLine.contract.number + '/' + res.elementNumber} has been updated (${round(res.quantityFixed, 3)} ${
            res.contractUnitAbbr
          })</div>`
        );
      })
      .catch((error) => {
        spinner.completeRequest(rid);
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const updatePriceFixationPreset: DynamicFormConstant<UpdatePriceFixationPrefill, UpdatePriceFixationRequest> = {
  allowMultipleRows: false,
  openForm: updatePriceFixationForm,
  entityType: SourceEntityType.PRICE_FIXING_KEY,
  getPrefill: updatePriceFixationPrefill,
  label: 'Update Price Fixation',
  submitForm: submitUpdatePriceFixation,
  title: 'Update Price Fixation',
  value: DynamicFormType.UPDATE_PRICE_FIXATION,
  endpoints: [
    endpoints.getPriceFixation,
    endpoints.getDailyExchangeRates,
    endpoints.listShipmentFixations,
    endpoints.updatePriceFixation,
    endpoints.applyPriceFixations,
    endpoints.unapplyPriceFixations,
  ],
  width: 840,
};

export type UpdatePriceFixationPrefill = {
  priceFixation: PriceFixationData;
  shipments: ShipmentFixations[];
  currencyConversions: ExchangeRateInfo[];
  counterparty: Contact;
};
export type PriceFixationData = Subset<
  PcPriceFixing,
  'id',
  | 'quantityFixed'
  | 'fixDate'
  | 'promptDate'
  | 'termPrice'
  | 'leftOverFutQtyComment'
  | 'contractLine'
  | 'elementNumber'
  | 'bradyQuantityFixed'
  | 'fxRateFixation'
  | 'marketValuation'
  | 'metalUnits'
  | 'metalUnitsPercentage'
  | 'contractId'
> &
  Omit<ContractLineFixationData, 'id' | 'lineNumber'> & {
    isFixationApplied: boolean;
    sumQuantityApplied: number;
  };
export type UpdatePriceFixationForm = UpdatePriceFixationRequest & {
  contractType?: ContractType;
  market?: string;
  quantityToFixBreakdown?: string;
  fixedContractPrice?: number;
  quantityToFix?: number;
  shipments?: ShipmentFixations[];
  applyShipmentsIds: number[];
  unapplyShipmentsIds: number[];
  sendEmail: FixationSendEmailOption;
};
