import { from, map, switchMap } from 'rxjs';
import { ClientInvoiceComponent, ClientInvoiceForm, ClientInvoicePrefill } from 'src/app/+modules/+accounting/client-invoice/client-invoice.component';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { ZeroDayBehavior, fromBradyDate, fromBradyDateOrNull, getTodayUTC, toBradyUTCDate } from 'src/lib/helperFunctions';
import {
  Contact,
  CreateClientInvoiceFixedLineRequest,
  CreateClientInvoiceProvisionalLineRequest,
  CreateClientInvoiceRequest,
  CurrencyConversion,
  EntryType,
  InvoiceHeader,
  OpenItem,
  PartialContact,
  ShipmentInformationForClientInvoice,
  SourceEntityType,
  YN,
} from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { PaymentHeader } from 'src/lib/newBackendTypes/payment';

const createClientInvoicePrefill: prefillCallback<ClientInvoicePrefill> = (delegate, id) => {
  const api = delegate.getService('api');

  const shipmentId = Array.isArray(id) ? id : [id];

  return api.rpc<ListResponse<ShipmentInformationForClientInvoice>>(endpoints.listShipmentInformationForClientInvoice, { filters: { shipmentId } }, null).pipe(
    switchMap(async (res) => {
      if (!res) return null;
      const shipments = res.list;
      const conversions = await api.run<CurrencyConversion[]>(endpoints.getDailyExchangeRates, { date: toBradyUTCDate(getTodayUTC()) }, []);
      const counterpartyId = shipments[0].customerId;
      const openItems = await api.run<ListResponse<OpenItem>>(endpoints.listOpenItems, { filters: { counterpartyId: counterpartyId } }, null);
      let counterpartyOpenItems: OpenItem[] = [];
      counterpartyOpenItems = openItems.list.filter((item) => item.balanceAmount < 0);

      return { shipments, conversions, counterpartyOpenItems };
    }),
    map((res: { shipments: ShipmentInformationForClientInvoice[]; conversions: CurrencyConversion[]; counterpartyOpenItems: OpenItem[] }): ClientInvoicePrefill | string => {
      if (!res || res.shipments?.length === 0) return 'Not available';

      const contracts: number[] = Array.from(new Set(res.shipments.map((s) => s.contractNumber)));

      if (contracts.length !== 1) return 'Cannot create one invoice for multiple contracts';

      let reservedInvoiceNumber: number | null = null;
      for (const shipment of res.shipments) {
        if (shipment.reservedInvoiceNumber > 0) {
          if (reservedInvoiceNumber < 0 && reservedInvoiceNumber !== shipment.reservedInvoiceNumber) return 'Cannot invoice shipments with different reserved invoice numbers';
          reservedInvoiceNumber = shipment.reservedInvoiceNumber;
        }
      }

      const counterparties: PartialContact[] = res.shipments.map((s) => {
        return { displayName: s.customerName, id: s.customerId, decimalPrecision: s.decimalPrecision };
      });

      const paymentTerms: any[] = res.shipments.map((s) => {
        return { name: s.contractPaymentTermName, id: s.contractPaymentTermId };
      });

      let permanentDate: Date;
      try {
        permanentDate = fromBradyDate(res.shipments[0].permanentDate, ZeroDayBehavior.FIRST_DAY_CURR_MONTH);
      } catch (e) {
        return 'Unable to determine open period date';
      }

      let invoiceDate: Date;
      try {
        invoiceDate = res.shipments[0].suggestedInvoiceDate > 0 ? fromBradyDate(res.shipments[0].suggestedInvoiceDate) : getTodayUTC();
        if (invoiceDate.getTime() < permanentDate.getTime()) {
          invoiceDate = new Date(permanentDate);
        }
      } catch (e) {
        return 'Invalid suggested invoice date';
      }

      let dueDate = fromBradyDateOrNull(res.shipments[0].finalDestinationEtaDate);
      if (!dueDate) {
        dueDate = new Date(invoiceDate);
        dueDate.setDate(dueDate.getDate() + 30);
      } else if (dueDate.getTime() < invoiceDate.getTime()) {
        dueDate = new Date(invoiceDate);
      }

      //salePriceFinalized === 'N'
      return {
        counterparty: counterparties[0] as Contact,
        permanentDate,
        invoiceDate,
        number: reservedInvoiceNumber,
        paymentTerm: paymentTerms[0],
        currencyConversions: res.conversions,
        dueDate,
        contractNumber: contracts[0],
        contractDate: res.shipments[0].contractDate,
        lines: res.shipments,
        paymentsCreditsLines: res.counterpartyOpenItems,
      };
    })
  );
};

const openClientInvoiceForm: openFormCallback<ClientInvoicePrefill, ClientInvoiceForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');

  return selector.openForm<ClientInvoiceForm, ClientInvoiceComponent, ClientInvoicePrefill>(ClientInvoiceComponent, {
    title: `Create Client Invoice: Contract ${prefill.contractNumber}`,
    width: '98%',
    prefillValue: prefill,
  });
};

const submitClientInvoice: submitFormCallback<ClientInvoicePrefill, ClientInvoiceForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');

  const lines = form.lines.map((line) => {
    const commonProps = {
      type: line.type,
      formula: line.formula,
      lineNote: line.lineNote,
      linePrice: line.linePrice,
      lineTotal: line.lineTotal,
      priceCurrencyId: line.priceCurrencyId,
      priceUnitId: line.priceUnitId,
      quantity: line.quantity,
      shipmentId: line.shipmentId,
    };
    return line.type === 'Provisional'
      ? {
          ...commonProps,
          collateralPercentage: line.collateralPercentage,
          collateralAmount: line.collateralAmount,
          fxRate: line.fxRate,
          marketDate: line.marketDate ? line.marketDate.date : null,
          marketPrice: line.marketPrice,
          marketValuationId: line.marketValuation.valuationId,
          materialPrice: line.materialPrice,
          premiumMarketPrice: line.premiumMarketPrice,
          premiumMarketValuationId: line.premiumMarketValuation ? line.premiumMarketValuation.valuationId : null,
        }
      : commonProps;
  }) as (CreateClientInvoiceProvisionalLineRequest | CreateClientInvoiceFixedLineRequest)[];

  const requests: CreateClientInvoiceRequest[] =
    form.oneShipmentPerInvoice === YN.Y
      ? lines.map((line, index) => ({
          number: index === 0 ? form.number : null,
          counterpartyId: form.counterparty.id,
          dueDate: form.dueDate,
          invoiceDate: form.invoiceDate,
          termId: form.paymentTerm.id,
          numberOfDecimals: form.numberOfDecimals,
          lines: [line],
        }))
      : [
          {
            number: form.number,
            counterpartyId: form.counterparty.id,
            dueDate: form.dueDate,
            invoiceDate: form.invoiceDate,
            termId: form.paymentTerm.id,
            numberOfDecimals: form.numberOfDecimals,
            lines,
          },
        ];

  return from(
    new Promise<{ successCICreated: InvoiceHeader[]; failedShipmentsIds: number[]; failedApplyPaymentsCredit: boolean }>((resolve, reject) => {
      (async () => {
        const successCICreated: InvoiceHeader[] = [];
        const failedShipmentsIds: number[] = [];
        let failedApplyPaymentsCredit = false;

        for (const request of requests) {
          const invoiceResponse = await api.run<InvoiceHeader>(endpoints.createClientInvoice, request, null);
          if (!invoiceResponse) failedShipmentsIds.push(...request.lines.map((item) => item.shipmentId));
          else successCICreated.push(invoiceResponse);
        }

        if (successCICreated.length > 0) {
          const paymentsCreditsLines = form.paymentsCreditsLines;
          if (paymentsCreditsLines && paymentsCreditsLines.length > 0) {
            const applyItemsRequest: ApplyItemsToVoucherRequest = {
              voucherId: successCICreated[0].id,
              valueDate: form.invoiceDate,
              entryDate: getTodayUTC(),
              entryIds: form.paymentsCreditsLines.map((item) => ({
                type: item.entryType,
                id: item.entryId,
                amount: item.amountToApply,
              })),
            };

            const applyItemsToVoucherResponse = await api.run<PaymentHeader>(endpoints.applyItemsToVoucher, applyItemsRequest, null);
            if (!applyItemsToVoucherResponse || !applyItemsToVoucherResponse.id) failedApplyPaymentsCredit = true;
          }

          await api.run<{ message: string }>(endpoints.generateClientInvoicesDocuments, { invoiceIds: successCICreated.map((inv) => inv.id) }, null);
        }
        if (successCICreated || failedShipmentsIds) return resolve({ successCICreated, failedShipmentsIds, failedApplyPaymentsCredit });
        return reject('Unknown result. Please check if the Client Invoice(s) were created and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const successMessage = 'Client Invoice(s) successfully created:';
          const errorMessage = 'An error ocurred with the following Client Invoice(s). Could not create:';
          const applyErrorMessage = 'Payments/Credits could NOT be applied to voucher. Apply the items manually or reverse the voucher and try again.';
          const failedApplyPaymentsCredit = res.failedApplyPaymentsCredit;
          const successNumbers = res.successCICreated.map((so) => `- ${so.number}`).join(`\n`);
          const failedIds = res.failedShipmentsIds.map((sof) => `- ${sof}`).join(`\n`);

          if (res.successCICreated && res.successCICreated.length > 0 && res.failedShipmentsIds && res.failedShipmentsIds.length > 0)
            return prompt.htmlDialog(
              'Warning',
              `<div style="white-space: pre">${successMessage} \n${successNumbers} \n\n${errorMessage} \n${failedIds} ${failedApplyPaymentsCredit ? '\n\n' + applyErrorMessage : ''}</div>`
            );
          if (res.successCICreated && res.successCICreated.length > 0 && !(res.failedShipmentsIds && res.failedShipmentsIds.length > 0))
            return prompt.htmlDialog('Success', `<div style="white-space: pre">${successMessage} \n${successNumbers} ${failedApplyPaymentsCredit ? '\n\n' + applyErrorMessage : ''}</div>`);
          if (!(res.successCICreated && res.successCICreated.length > 0) && res.failedShipmentsIds && res.failedShipmentsIds.length > 0)
            return prompt.htmlDialog('Error', `<div style="white-space: pre">${errorMessage} \n${failedIds} ${failedApplyPaymentsCredit ? '\n\n' + applyErrorMessage : ''}</div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const clientInvoicePreset: DynamicFormConstant<ClientInvoicePrefill, ClientInvoiceForm> = {
  allowMultipleRows: true,
  title: 'Client Invoice',
  value: DynamicFormType.CREATE_CLIENT_INVOICE,
  label: 'Client Invoice',
  entityType: SourceEntityType.CHUNK_KEY,
  getPrefill: createClientInvoicePrefill,
  openForm: openClientInvoiceForm,
  submitForm: submitClientInvoice,
  endpoints: [endpoints.listShipmentInformationForClientInvoice, endpoints.createClientInvoice, endpoints.getDailyExchangeRates, endpoints.generateClientInvoicesDocuments],
};

export type ApplyItemsToVoucherRequest = {
  voucherId: number;
  valueDate: Date;
  entryDate: Date;
  entryIds: { type: EntryType; id: number; amount: number }[];
};
