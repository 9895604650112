import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DateRangePopupComponent } from '@progress/kendo-angular-dateinputs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { ContractLineForm } from 'src/app/+modules/+trading/containers/base-contract/base-contract.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModalFormComponent, SelectorPopupService } from 'src/app/core/services/selector-popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Store } from 'src/app/core/services/store.service';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { NumerictextboxWrapperComponent } from 'src/app/shared/form-elements/numerictextbox-wrapper/numerictextbox-wrapper.component';
import { ItemComponent, ItemForm } from 'src/app/shared/item/item.component';
import { endpoints } from 'src/lib/apiEndpoints';
import { DropdownConfig } from 'src/lib/DropdownConfig';
import { endpointAuthorizationSubscription, endpointsAuthorized, markFormGroupTouched } from 'src/lib/helperFunctions';
import { ContractClass, Currency, Incoterm, Product, Unit, YN } from 'src/lib/newBackendTypes';
import { ContainerType } from 'src/lib/newBackendTypes/containerType';
import { ShipmentPeriodType } from 'src/lib/newBackendTypes/contractLine';
import { Country } from 'src/lib/newBackendTypes/country';
import { CommonSteelTypeGroups, CreateItemRequest, Item } from 'src/lib/newBackendTypes/item';
import { MetalControlGroup } from 'src/lib/newBackendTypes/metalControlGroup';
import { TypedFormGroup } from 'src/lib/typedForms';
import { basicGraphqlCityDropdown } from 'src/lib/graphql/graphQlFunctions';

@UntilDestroy()
@Component({
  selector: 'contract-line-editor',
  templateUrl: './contract-line-editor.component.html',
  styleUrls: ['./contract-line-editor.component.scss'],
})
export class ContractLineEditorComponent implements OnInit, OnDestroy, ModalFormComponent<ContractLineForm> {
  unitSource = new DropdownConfig<Unit>(endpoints.listUnits, 'code', 'unitId');
  containerTypeSource = new DropdownConfig<ContainerType>(endpoints.listContainerTypes, 'name', 'id');
  countrySource = new DropdownConfig<Country>(endpoints.listCountries, 'name', 'id');
  itemSource = new DropdownConfig<Item>(endpoints.listItems, 'name', 'id', [], {
    groupTypeId: CommonSteelTypeGroups.DEFAULT,
  });
  allItemSource = new DropdownConfig<Item>(endpoints.listItems, 'name', 'id');
  citySource = basicGraphqlCityDropdown();
  metalControlDropdown = new DropdownConfig<MetalControlGroup>({
    listProcedure: endpoints.listMetalControlGroups,
    labelField: 'statusName',
    valueField: 'statusKey',
    postFilter: (v) => !!v.statusKey && !!v.statusName,
  });

  @ViewChild('quantityField', { static: false })
  quantityField: NumerictextboxWrapperComponent;

  form: TypedFormGroup<ContractLineForm>;

  @Input()
  product: Product | null;

  @Input()
  incoterm: Incoterm;

  @Input()
  contractClass: ContractClass;

  @Input()
  priceUnit: Unit | null;

  @Input()
  currency: Currency | null;

  @Input()
  quantityUnit: Unit | null;

  @Input()
  premiumUnit: Unit | null;

  @Input()
  premiumCurrency: Currency | null;

  @Input()
  contractDate: Date | null;

  shipmentPeriodData: { label: string; value: ShipmentPeriodType }[] = [];

  authorized: endpointsAuthorized;

  popup = true;

  usesRecoveryPercentageOptions = [
    { value: YN.Y, label: 'Yes' },
    { value: YN.N, label: 'No' },
  ];

  constructor(store: Store, private selectorService: SelectorPopupService, private spinnerService: SpinnerService, private notificationService: NotificationService, private api: ThalosApiService) {
    this.shipmentPeriodData = [
      {
        label: 'Delivery',
        value: ShipmentPeriodType.DELIVERY,
      },
      {
        label: 'Shipment',
        value: ShipmentPeriodType.SHIPMENT,
      },
    ];

    endpointAuthorizationSubscription(store, this);

    this.form = new TypedFormGroup<ContractLineForm>({
      id: new UntypedFormControl(),
      shipmentPeriodStart: new UntypedFormControl(null, [this.contractDateValidator()]),
      shipmentPeriodEnd: new UntypedFormControl(null),
      shipmentPeriodType: new UntypedFormControl(null),
      quantity: new UntypedFormControl(),
      containerTypes: new UntypedFormControl(),
      originCountry: new UntypedFormControl(),
      destination: new UntypedFormControl(),
      incotermPlace: new UntypedFormControl(),
      item: new UntypedFormControl(),
      clientItem: new UntypedFormControl(),
      pricing: new UntypedFormControl(),
      priceType: new UntypedFormControl(),
      lineNumber: new UntypedFormControl(),
      metalPercentage: new UntypedFormControl(),
      metalControlGroup: new UntypedFormControl(),
      recoveryPercentage: new UntypedFormControl(),
      usesRecoveryPercentage: new UntypedFormControl(YN.N),
      finished: new UntypedFormControl(YN.N),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contractDate'] && changes['contractDate'].currentValue !== changes['contractDate'].previousValue) {
      setTimeout(() => {
        const startControl = this.form.get('shipmentPeriodStart');
        startControl.updateValueAndValidity({ onlySelf: true });
        if (startControl.invalid && !!startControl.value && typeof startControl.value !== 'string') {
          startControl.markAsTouched({ onlySelf: true });
        }
      });
    }
    if (changes['product'] && changes['product'].currentValue?.productId !== changes['product'].previousValue?.productId) {
      if (this.product) {
        this.itemSource.additionalFilters = { productId: this.product.productId };
        this.allItemSource.additionalFilters = { productId: this.product.productId };
      } else {
        this.itemSource.additionalFilters = {};
        this.allItemSource.additionalFilters = {};
      }
    }
  }

  ngOnInit() {
    this.form
      .get('shipmentPeriodStart')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        setTimeout(() => {
          const endControl = this.form.get('shipmentPeriodEnd');
          endControl.updateValueAndValidity({ onlySelf: true });
          if (endControl.invalid && !!endControl.value && typeof endControl.value !== 'string') endControl.markAsTouched({ onlySelf: true });
        });
      });

    if (this.product) {
      this.itemSource.additionalFilters = { productId: this.product.productId };
      this.allItemSource.additionalFilters = { productId: this.product.productId };
    } else {
      this.itemSource.additionalFilters = {};
      this.allItemSource.additionalFilters = {};
    }

    this.form
      .get('shipmentPeriodStart')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((e) => {
        if (!!this.form.value.id) return;
        let date;
        if (e) {
          try {
            date = this.plusOneMonth(e);
            if (this.form.get('shipmentPeriodEnd').untouched) this.form.get('shipmentPeriodEnd').setValue(date);
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  ngOnDestroy() {}

  ngAfterViewInit(): void {
    if (this.form && this.form.touched && !this.form.value.id) {
      this.quantityField.focus();
    }
  }

  public selectNewDate(val, type, formControl) {
    let date = new Date(val);

    if (date) {
      if (type === 'start') {
        formControl.setValue(date);
      } else {
        formControl.setValue(date);
        formControl.markAsTouched();
      }
    }
  }

  public plusOneMonth(d: Date) {
    let date = new Date(d);
    let m = date.getMonth();
    if (m == 1) date.setDate(date.getDate() + 30);
    else date.setMonth(date.getMonth() + 1);

    return date;
  }

  public dateBlur(popup: DateRangePopupComponent) {
    popup.toggle();
  }
  public dateFocus(popup: DateRangePopupComponent) {
    popup.toggle();
    popup.open.pipe(first()).subscribe(() => {
      popup.calendar.focus();
    });

    popup.onBlur.pipe(first()).subscribe(() => this.dateBlur(popup));
  }

  openCreateItem(type: 'client' | 'internal') {
    if (!this.product) return;
    this.selectorService
      .openForm<ItemForm, ItemComponent>(ItemComponent, {
        title: type === 'client' ? 'New Client Item' : 'New Standard Item',
        initializer: (c) => {
          c.type = type;
        },
        width: 300,
      })
      .subscribe((itemForm) => {
        if (itemForm === 'Close') return;
        if (!this.product) return;
        let request: CreateItemRequest = {
          qualityTypeId: itemForm.qualityType ? itemForm.qualityType.qualityTypeKey : null,
          name: itemForm.name,
          productId: this.product.productId,
          groupTypeId: type === 'internal' ? CommonSteelTypeGroups.DEFAULT : CommonSteelTypeGroups.CLIENT,
          reportingGroupId: itemForm.reportingGroup ? itemForm.reportingGroup.id : null,
          analyticGroupId: itemForm.analyticGroup ? itemForm.analyticGroup.id : null,
          metalUnitPercentage: itemForm.metalUnitPercentage ?? null,
        };
        let rid = this.spinnerService.startRequest('Saving Item');
        this.api.rpc<Item>(endpoints.createItem, request, null).subscribe((res) => {
          this.spinnerService.completeRequest(rid);
          if (res) {
            this.notificationService.show('Item Created', 'success');
            this.form.patchValue(type === 'client' ? { clientItem: res } : { item: res });
          } else {
            this.notificationService.show('Item Creation Failed', 'error');
          }
        });
      });
  }

  get createItemAuthorized() {
    return this.authorized[endpoints.createItem];
  }

  prefillForm(line: ContractLineForm) {
    this.form.patchValue({
      ...line,
    });
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid;
  }

  submit() {
    return this.form.value;
  }

  public contractDateValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (this.contractDate === null || control.value === null) return;
      let cDate, date;
      try {
        cDate = new Date(this.contractDate);
        date = new Date(control.value);
      } catch {
        cDate = null;
        date = null;
      }
      if (cDate) cDate.setUTCHours(0, 0, 0, 0);
      if (date) date.setUTCHours(0, 0, 0, 0);
      return !!cDate && !!date && cDate > date ? { afterContractDate: true } : null;
    };
  }
}
