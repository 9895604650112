import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { round } from 'lodash';
import { isObservable, of } from 'rxjs';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DataFormattingService } from 'src/app/core/services/data-formatting.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { CustomDialogResult, ModalFormComponent, SelectorApi } from 'src/app/core/services/selector-popup.service';
import { dollarFormat, fixationRateFormat, lbPriceFormat, mtWeightFormat } from 'src/lib/commonTypes';
import { PriceFixationData, UpdatePriceFixationForm, UpdatePriceFixationPrefill, updatePriceFixationPreset } from 'src/lib/flex/forms/updatePriceFixation';
import { conditionalValidators, fixationRateValidator, metalUnitPercentageValidator, quantityToFixValidator } from 'src/lib/genericValidators';
import { markFormGroupTouched, roundAmount, weightFormat } from 'src/lib/helperFunctions';
import { CommonUnits, CommonUnitsNameMap, Contact, ContactMethodType, ContractType, ContractTypes, PhysicalPricingType, UnitInfo } from 'src/lib/newBackendTypes';
import { ExchangeRateInfo } from 'src/lib/newBackendTypes/exchangeRateInfo';
import { FixationSendEmailOption, FixationSendEmailOptions } from 'src/lib/newBackendTypes/priceFixation';
import { TypedFormGroup } from 'src/lib/typedForms';

@Component({
  selector: 'hedging-update-price-fixation',
  templateUrl: './update-price-fixation.component.html',
})
export class UpdatePriceFixationComponent implements ModalFormComponent<UpdatePriceFixationForm, UpdatePriceFixationPrefill> {
  form: TypedFormGroup<UpdatePriceFixationForm>;
  popup = true;
  selectorApi: SelectorApi;

  weightFormat: NumberFormatOptions;
  marketPriceFormat: NumberFormatOptions;
  fixedContractPriceFormat: NumberFormatOptions;
  fixationRateFormat = fixationRateFormat();
  metalUnitPercentageFormat = mtWeightFormat();
  muDecimals: number;
  contractTypes = ContractTypes;
  showCounter = true;
  maxlength = 255;

  // Market
  market: string = '';
  marketCurrAbbr: string = '';
  marketUnitAbbr: string = '';
  marketUnitId: number | null = null;
  marketCurrId: number | null = null;
  spread: number | null = null;
  percentage: number | null = null;
  // Premium Market
  premiumValuationName: string = '';
  premiumMarketCurrAbbr: string = '';
  premiumMarketUnitAbbr: string = '';
  premiumFixedMarketPrice: number | null = null;
  premiumSpread: number | null = null;
  premimumPercentage: number | null = null;
  premiumUnit: number | null = null;
  premiumCurrId: number | null = null;

  // Premium
  fixedPremium: number | null = null;
  contractCurrAbbr: string = '';
  contractPriceUnitAbbr: string = '';
  contractPriceUnitId: number | null = null;
  contractCurrencyId: number | null = null;
  contractUnitAbbr: string = '';
  contractQtyUnitKey: number | null = null;
  productId: number | null = null;

  //Currencies
  currencyConversions: ExchangeRateInfo[];

  contractPriceFactor: number | null = null;
  quantityToFixBreakdown: string = '';
  fixedContractPrice: number | null = null;
  quantityToFix: number | null = null;
  quantityToFixNew: number | null = null;
  quantityFixed: number | null = null;
  hasDifferentCurrencies: boolean = false;
  sumQuantityApplied: number | null = null;
  isFixationApplied: boolean = false;

  data: UpdatePriceFixationPrefill;
  dataPriceFixation: PriceFixationData;
  counterparty: Contact;

  /**
   * Inputs for the shipments fixations list
   */
  contractType: ContractType;
  fixWeight: number | null = null;
  contractPF: number | null = null;
  canApply: boolean = false;

  emailOptions = FixationSendEmailOptions;

  constructor(public delegate: DelegateService, public commonData: CommonDataService, private formatter: DataFormattingService) {
    this.form = new TypedFormGroup<UpdatePriceFixationForm>({
      id: new UntypedFormControl(null),
      quantityFixed: new UntypedFormControl(null),
      fixDate: new UntypedFormControl(null, Validators.required),
      contractType: new UntypedFormControl(null),
      termPrice: new UntypedFormControl(null, Validators.required),
      market: new UntypedFormControl(''),
      quantityToFixBreakdown: new UntypedFormControl(''),
      fixedContractPrice: new UntypedFormControl(null),
      quantityToFix: new UntypedFormControl(null),
      metalUnits: new UntypedFormControl(null, Validators.required),
      metalUnitsPercentage: new UntypedFormControl(null, [Validators.required, metalUnitPercentageValidator()]),
      leftOverFutQtyComment: new UntypedFormControl(''),
      fixationRate: new UntypedFormControl(null),
      sendEmail: new UntypedFormControl(FixationSendEmailOption.DO_NOT_SEND_EMAIL),
      shipments: new UntypedFormControl([]),
      applyShipmentsIds: new UntypedFormControl([]),
      unapplyShipmentsIds: new UntypedFormControl([]),
    });
  }

  calculateMetalUnits() {
    const quantityFixed = this.form.get('quantityFixed').value;
    const mup = this.form.get('metalUnitsPercentage').value;
    let metalUnits = (mup * quantityFixed) / 100;
    metalUnits = this.formatter.roundQuantity(metalUnits, this.contractQtyUnitKey);
    this.form.patchValue({ metalUnits });
    this.form.get('metalUnits').updateValueAndValidity();
  }

  calculateMetalUnitsPercentage() {
    const quantityFixed = this.form.get('quantityFixed').value;
    const mu = this.form.get('metalUnits').value;
    let metalUnitsPercentage = (mu / quantityFixed) * 100;
    metalUnitsPercentage = round(metalUnitsPercentage, 3);
    this.form.patchValue({ metalUnitsPercentage });
    this.form.get('metalUnitsPercentage').markAsTouched();
    this.form.get('metalUnitsPercentage').updateValueAndValidity();
  }

  validateCurrencies(marketCurrAbbr: number, contractCurrAbbr: number) {
    marketCurrAbbr === contractCurrAbbr ? (this.hasDifferentCurrencies = false) : (this.hasDifferentCurrencies = true);

    if (!this.hasDifferentCurrencies) {
      this.form.patchValue({
        fixationRate: 1,
      });
    }
  }

  calculateFixedContractPrice() {
    const termPriceVal = this.form.get('termPrice').value;
    let market = null;
    if (!!termPriceVal) {
      let marketFormula = ((termPriceVal + this.spread) * this.percentage) / 100;
      market = this.makeConversions(marketFormula, this.marketUnitId, this.marketCurrId);
    }

    // Find Decimal Precision from Prov Invoice Keyword; if not found, take it from Alf
    const shipments = this.form.get('shipments').value;
    const decimalPrecisionKeyword = shipments && shipments[0] && this.contractType === ContractType.SALE ? shipments[0].saleInvoiceDecimalPrecision : shipments[0].purchInvoiceDecimalPrecision;
    const decimalPrecision = decimalPrecisionKeyword ? decimalPrecisionKeyword : this.counterparty.decimalPrecision;

    const premiumMarket = this.calculatePremiumMarket();
    this.fixedContractPrice = market !== null && premiumMarket !== null ? market + premiumMarket + this.fixedPremium : null;
    this.fixedContractPrice = roundAmount(this.fixedContractPrice, decimalPrecision);

    this.form.patchValue({
      fixedContractPrice: this.fixedContractPrice,
    });
  }

  calculatePremiumMarket() {
    if (this.premiumSpread !== null && this.premimumPercentage !== null) {
      if (this.premiumFixedMarketPrice !== null) {
        const premiumMarketFormula = ((this.premiumFixedMarketPrice + this.premiumSpread) * this.premimumPercentage) / 100;
        this.canApply = true;
        return this.makeConversions(premiumMarketFormula, this.premiumUnit, this.premiumCurrId);
      } else {
        this.canApply = false;
        return null;
      }
    }
    this.canApply = true;
    return 0;
  }

  makeConversions(formula: number, unitId: number, currencyId: number): number {
    const fxRate = this.form.get('fixationRate').value;
    // Conversion of base unit to MT
    const marketUnit = this.commonData.staticUnits.value.find((u) => u.unitId === unitId);
    const marketUnitFactor: UnitInfo = marketUnit.unitFactors.find((f) => f.productId === this.productId);

    // Conversion of base currency to dollars
    const currencyRate = this.currencyConversions.find((fx) => fx.baseCurrencyId === currencyId && fx.targetCurrencyId === 3);

    const calc = ((formula / fxRate) * currencyRate.factor) / marketUnitFactor.factor;

    // Return the calculation converted to the contract unit
    return calc * this.contractPriceFactor;
  }

  calculateQuantityToFix(value: number) {
    this.fixWeight = value;
    const quantities = this.quantityToFix + this.quantityFixed;
    this.quantityToFixNew = quantities - value;

    this.form.patchValue({
      quantityToFix: this.quantityToFixNew,
    });

    this.form.get('quantityFixed').setValidators([Validators.required, quantityToFixValidator(quantities, this.contractUnitAbbr, this.sumQuantityApplied)]);
  }

  shipmentsAppliedHandler(applyShipmentsIds: number[]) {
    this.form.patchValue({ applyShipmentsIds });
  }

  shipmentsUnappliedHandler(unapplyShipmentsIds: number[]) {
    this.form.patchValue({ unapplyShipmentsIds });
  }

  prefillForm(data: UpdatePriceFixationPrefill) {
    if (!!data) {
      this.data = data;
      this.dataPriceFixation = data.priceFixation;
      this.counterparty = data.counterparty;

      const tolerance = this.dataPriceFixation.contractLine?.contract?.tolerance;
      let marketPricing = this.dataPriceFixation.contractLine?.pricing;

      for (const pricing of marketPricing) {
        switch (pricing.pricingType) {
          case PhysicalPricingType.PRICE:
            // Market
            this.spread = pricing.spread;
            this.percentage = pricing.percentage;
            this.marketUnitId = pricing.marketValuation?.unitId;
            this.marketCurrId = pricing.marketValuation?.currencyId;
            break;
          case PhysicalPricingType.PREMIUM:
            // Premium Market
            this.premiumSpread = pricing.spread;
            this.premimumPercentage = pricing.percentage;
            this.premiumUnit = pricing.marketValuation?.unitId;
            this.premiumCurrId = pricing.marketValuation?.currencyId;
            break;
        }
      }

      //Currencies
      this.currencyConversions = this.data.currencyConversions;

      // Market
      this.market = this.dataPriceFixation.screenFormula;
      this.marketCurrAbbr = this.dataPriceFixation.marketCurrAbbr;
      this.marketUnitAbbr = this.dataPriceFixation.marketUnitAbbr;
      // Premium Market
      this.premiumValuationName = this.dataPriceFixation.contractLine?.pricing[1]?.marketValuation?.valuationName;
      this.premiumMarketCurrAbbr = this.dataPriceFixation.premiumMarketCurrAbbr;
      this.premiumMarketUnitAbbr = this.dataPriceFixation.premiumMarketUnitAbbr;
      this.premiumFixedMarketPrice = this.dataPriceFixation.premiumFixedMarketPrice;
      // Premium
      this.fixedPremium = this.dataPriceFixation.contractLine?.premium;
      this.contractCurrAbbr = this.dataPriceFixation.contractCurrAbbr;
      this.contractPriceUnitAbbr = this.dataPriceFixation.contractPriceUnitAbbr;
      this.contractPriceUnitId = this.data.priceFixation.contractLine.contract.priceUnitFactor.unitId;
      this.contractCurrencyId = this.dataPriceFixation.contractCurrencyId;
      this.contractUnitAbbr = this.dataPriceFixation.contractUnitAbbr;
      this.contractQtyUnitKey = this.dataPriceFixation.contractLine?.contract.quantityUnitId;
      this.productId = this.dataPriceFixation.contractLine?.contract?.productId;

      this.muDecimals = this.contractUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? 3 : 0;

      if (this.contractPriceUnitId && this.productId) {
        const contractPriceUnit = this.commonData.staticUnits.value.find((u) => u.unitId === this.contractPriceUnitId);
        if (contractPriceUnit) {
          const contractPriceFactor = contractPriceUnit.unitFactors.find((f) => f.productId === this.productId);
          if (contractPriceFactor) this.contractPriceFactor = contractPriceFactor.factor;
        }
      }

      this.weightFormat = this.formatter.getUnitsFormat(this.contractUnitAbbr);
      this.marketPriceFormat = this.marketUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? dollarFormat() : lbPriceFormat();
      //TODO: Implement getPriceFormat in the rest of required fields
      this.fixedContractPriceFormat = this.formatter.getPriceFormat(this.contractCurrencyId, this.contractPriceUnitId);
      this.quantityFixed = this.formatter.roundQuantity(
        this.dataPriceFixation.quantityFixed ? this.dataPriceFixation.quantityFixed : this.dataPriceFixation.bradyQuantityFixed * (1 + tolerance / 100),
        this.contractQtyUnitKey
      );
      this.quantityToFix = this.formatter.roundQuantity(this.dataPriceFixation.quantityPendingToFix, this.contractQtyUnitKey);
      this.sumQuantityApplied = this.dataPriceFixation.sumQuantityApplied;
      this.isFixationApplied = this.dataPriceFixation.isFixationApplied;
      this.contractType = this.dataPriceFixation.contractLine?.contract?.type;
      this.fixWeight = this.quantityFixed;
      this.contractPF = this.dataPriceFixation.id;
      const quantity = this.dataPriceFixation.contractLine.quantity
        ? weightFormat(this.dataPriceFixation.contractLine.quantity, this.contractUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? 3 : 0)
        : 0;
      const quantityFixed = this.dataPriceFixation.quantityFixed ? weightFormat(this.dataPriceFixation.quantityFixed, this.contractUnitAbbr === CommonUnitsNameMap[CommonUnits.MT] ? 3 : 0) : 0;
      this.quantityToFixBreakdown = `${
        quantity + ' ' + this.contractUnitAbbr + ' + ' + tolerance + '% TOL - ' + quantityFixed + ' ' + this.contractUnitAbbr + ' QTY Fixed = ' + this.quantityToFix + ' ' + this.contractUnitAbbr
      }`;

      this.form.patchValue({
        ...data,
        fixDate: this.dataPriceFixation.fixDate,
        contractType: this.contractType,
        market: this.market,
        termPrice: this.dataPriceFixation.termPrice,
        quantityToFixBreakdown: this.quantityToFixBreakdown,
        quantityFixed: this.quantityFixed,
        metalUnits: this.dataPriceFixation.metalUnits,
        metalUnitsPercentage: this.dataPriceFixation.metalUnitsPercentage,
        fixationRate: this.dataPriceFixation.fxRateFixation?.fixationRate,
        leftOverFutQtyComment: this.dataPriceFixation.leftOverFutQtyComment,
      });

      const requiredIfNotApplied = conditionalValidators(() => !this.isFixationApplied, Validators.required, fixationRateValidator());
      this.form.get('fixationRate').setValidators(requiredIfNotApplied);
      this.validateCurrencies(this.dataPriceFixation.marketCurrId, this.dataPriceFixation.contractCurrencyId);
      this.calculateFixedContractPrice();
      this.calculateQuantityToFix(this.quantityFixed);
    }
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    const dialogService = this.delegate.getService('dialog');

    if (!this.form.valid) return false;

    if (formVal.unapplyShipmentsIds.length !== 0) {
      const dialog = dialogService.open({
        title: 'Unapply Price Fixation',
        content: `This action will REMOVE the fixation from shipment(s) "${formVal.unapplyShipmentsIds.join(', ')}". \nAre you SURE you wish to proceed?`,
        actions: [
          {
            text: 'No',
          },
          {
            text: 'Yes',
            themeColor: 'primary',
            primary: true,
          },
        ],
      });

      dialog.result.subscribe((res: CustomDialogResult) => {
        if (res instanceof DialogCloseResult || !res.primary) return of('Close');
        else {
          this.selectorApi.close();
          const submit = this.submit();
          const res = updatePriceFixationPreset.submitForm(this.delegate, this.dataPriceFixation.id, submit, this.data);
          if (res) {
            if (isObservable(res)) return res;
            else return of(res);
          }
        }
      });
    } else return true;
  }

  submit(): UpdatePriceFixationForm {
    markFormGroupTouched(this.form);
    const formVal = this.form.value;
    if (!this.isFixationApplied) {
      return {
        id: formVal.id,
        quantityFixed: formVal.quantityFixed,
        fixDate: formVal.fixDate,
        termPrice: round(formVal.termPrice, 4),
        fixedContractPrice: formVal.fixedContractPrice,
        leftOverFutQtyComment: formVal.leftOverFutQtyComment,
        fixationRate: formVal.fixationRate,
        applyShipmentsIds: formVal.applyShipmentsIds,
        unapplyShipmentsIds: formVal.unapplyShipmentsIds,
        metalUnits: this.formatter.roundQuantity(formVal.metalUnits, this.contractQtyUnitKey),
        metalUnitsPercentage: round(formVal.metalUnitsPercentage, 3),
        sendEmail: formVal.sendEmail,
      };
    } else {
      return {
        id: formVal.id,
        quantityFixed: formVal.quantityFixed,
        leftOverFutQtyComment: formVal.leftOverFutQtyComment,
        applyShipmentsIds: formVal.applyShipmentsIds,
        unapplyShipmentsIds: formVal.unapplyShipmentsIds,
        metalUnits: this.formatter.roundQuantity(formVal.metalUnits, this.contractQtyUnitKey),
        metalUnitsPercentage: round(formVal.metalUnitsPercentage, 3),
        sendEmail: formVal.sendEmail,
      };
    }
  }

  get hasPricingConfEmail(): boolean {
    return this.data.priceFixation.contractLine.contract.counterparty.methods.some((method) => method.type === ContactMethodType.PCE);
  }
}
